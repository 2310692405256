import { BankId, type BankIdResponse } from '@careos/react-ui/BankId';
import { useEffect, useState, memo, useCallback, useRef } from 'react';

import { useBankIdPolling, type BankIdInitiate } from '../hooks/use-bankid-polling';

type ScanQRProps = {
  transactionId: string;
  onCancel: () => void;
} & Pick<BankIdInitiate, 'onCompletion'>;

const ScanQRComponent = ({ transactionId, onCancel, onCompletion }: ScanQRProps) => {
  const { init, abort } = useBankIdPolling();
  const [bankIdData, setBankIdData] = useState<BankIdResponse>();
  const hasBeenInitiated = useRef(false);

  const handleRetryPolling = () => {
    hasBeenInitiated.current = false;
    startPolling();
  };

  const startPolling = useCallback(() => {
    if (hasBeenInitiated.current) return;
    init({
      flowType: 'qr',
      signArgs: {
        transactionId,
      },
      onError: (error) => {
        setBankIdData({
          status: 'error',
          ...error,
        });
      },
      onCompletion,
      onUpdate: setBankIdData,
    });
    hasBeenInitiated.current = true;
  }, [init, transactionId, onCompletion]);

  useEffect(() => {
    startPolling();
    return () => {
      abort();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BankId
      flowType="qr"
      onTryAgain={handleRetryPolling}
      onCancel={onCancel}
      bankIdResponse={bankIdData}
    />
  );
};

export const ScanQR = memo(ScanQRComponent);
