import {
  isDoaActivityIdentifierWithSamplingKit,
  ToxicologyActivityIdentifier,
  type SamplingKitIdentifier,
} from '@careos/identifiers';
import { queryOptions, useQuery } from '@tanstack/react-query';

import { api } from '@/lib/api';
import type { QueryConfig } from '@/lib/react-query';

const getPanelsQueryOptions = (
  activityDefinition: ToxicologyActivityIdentifier,
  samplingKit?: SamplingKitIdentifier,
) => {
  const enabled =
    (isDoaActivityIdentifierWithSamplingKit(activityDefinition) && !!samplingKit) ||
    activityDefinition === ToxicologyActivityIdentifier.DOA_URINE_SAMPLING;

  return queryOptions({
    queryKey: ['panels', activityDefinition, samplingKit],
    queryFn: () =>
      api.config.getPanelSizesWithAdditionalSubstances(activityDefinition, samplingKit),
    enabled,
    select: (data) => data.panels,
  });
};

export const usePanels = ({
  activityDefinition,
  samplingKit,
  queryConfig,
}: {
  activityDefinition: ToxicologyActivityIdentifier;
  samplingKit?: SamplingKitIdentifier;
  queryConfig?: QueryConfig<typeof getPanelsQueryOptions>;
}) => {
  return useQuery({
    ...getPanelsQueryOptions(activityDefinition, samplingKit),
    ...queryConfig,
  });
};
