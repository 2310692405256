import { useAuth0, type Auth0ContextInterface } from '@auth0/auth0-react';
import type { Auth0CareosUser } from '@careos/react-ui/AuthenticatedComponent';
import { Toaster } from '@careos/react-ui/Toast';
import { type Locale } from '@careos/types';
import type { QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';
import { TanStackRouterDevtools } from '@tanstack/react-router-devtools';

import { Header } from '@/components/header';
import type { CollectionStore } from '@/features/order/form/stores/collection-store';

type TRouterContext = {
  auth: Auth0ContextInterface<Auth0CareosUser>;
  queryClient: QueryClient;
  collectionStore: CollectionStore;
  locale: Locale;
  remoteSigning?: { pinCode: string; pdf: string };
};

export const Route = createRootRouteWithContext<TRouterContext>()({
  component: RootComponent,
});

function RootComponent() {
  const { isAuthenticated, logout, user } = useAuth0<Auth0CareosUser>();

  return (
    <>
      {isAuthenticated && user && <Header onLogout={logout} user={user} />}
      <Toaster />
      <main className="min-h-[calc(100dvh_-_58px)]">
        <Outlet />
      </main>
      <TanStackRouterDevtools />
      <ReactQueryDevtools />
    </>
  );
}
