/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as CallbackImport } from './routes/callback'
import { Route as AuthenticatedImport } from './routes/_authenticated'
import { Route as IndexImport } from './routes/index'
import { Route as DonorSuccessImport } from './routes/donor/success'
import { Route as DonorTransactionIdRouteImport } from './routes/donor/$transactionId/route'
import { Route as AuthenticatedSessionsIndexImport } from './routes/_authenticated/sessions/index'
import { Route as DonorTransactionIdPinCodeImport } from './routes/donor/$transactionId/pin-code'
import { Route as DonorTransactionIdSignConsentRouteImport } from './routes/donor/$transactionId/sign-consent/route'
import { Route as AuthenticatedSessionsSessionIdRouteImport } from './routes/_authenticated/sessions/$sessionId/route'
import { Route as DonorTransactionIdSignConsentIndexImport } from './routes/donor/$transactionId/sign-consent/index'
import { Route as DonorTransactionIdSignConsentHandwrittenImport } from './routes/donor/$transactionId/sign-consent/handwritten'
import { Route as DonorTransactionIdSignConsentBankIdImport } from './routes/donor/$transactionId/sign-consent/bank-id'
import { Route as AuthenticatedSessionsSessionIdTestInfoImport } from './routes/_authenticated/sessions/$sessionId/test-info'
import { Route as AuthenticatedSessionsSessionIdSignedImport } from './routes/_authenticated/sessions/$sessionId/signed'
import { Route as AuthenticatedSessionsSessionIdSignImport } from './routes/_authenticated/sessions/$sessionId/sign'
import { Route as AuthenticatedSessionsSessionIdSamplingImport } from './routes/_authenticated/sessions/$sessionId/sampling'
import { Route as AuthenticatedSessionsSessionIdDonorInfoImport } from './routes/_authenticated/sessions/$sessionId/donor-info'
import { Route as AuthenticatedSessionsSessionIdCreatedImport } from './routes/_authenticated/sessions/$sessionId/created'

// Create/Update Routes

const CallbackRoute = CallbackImport.update({
  id: '/callback',
  path: '/callback',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedRoute = AuthenticatedImport.update({
  id: '/_authenticated',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const DonorSuccessRoute = DonorSuccessImport.update({
  id: '/donor/success',
  path: '/donor/success',
  getParentRoute: () => rootRoute,
} as any)

const DonorTransactionIdRouteRoute = DonorTransactionIdRouteImport.update({
  id: '/donor/$transactionId',
  path: '/donor/$transactionId',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedSessionsIndexRoute = AuthenticatedSessionsIndexImport.update(
  {
    id: '/sessions/',
    path: '/sessions/',
    getParentRoute: () => AuthenticatedRoute,
  } as any,
)

const DonorTransactionIdPinCodeRoute = DonorTransactionIdPinCodeImport.update({
  id: '/pin-code',
  path: '/pin-code',
  getParentRoute: () => DonorTransactionIdRouteRoute,
} as any)

const DonorTransactionIdSignConsentRouteRoute =
  DonorTransactionIdSignConsentRouteImport.update({
    id: '/sign-consent',
    path: '/sign-consent',
    getParentRoute: () => DonorTransactionIdRouteRoute,
  } as any)

const AuthenticatedSessionsSessionIdRouteRoute =
  AuthenticatedSessionsSessionIdRouteImport.update({
    id: '/sessions/$sessionId',
    path: '/sessions/$sessionId',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

const DonorTransactionIdSignConsentIndexRoute =
  DonorTransactionIdSignConsentIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => DonorTransactionIdSignConsentRouteRoute,
  } as any)

const DonorTransactionIdSignConsentHandwrittenRoute =
  DonorTransactionIdSignConsentHandwrittenImport.update({
    id: '/handwritten',
    path: '/handwritten',
    getParentRoute: () => DonorTransactionIdSignConsentRouteRoute,
  } as any)

const DonorTransactionIdSignConsentBankIdRoute =
  DonorTransactionIdSignConsentBankIdImport.update({
    id: '/bank-id',
    path: '/bank-id',
    getParentRoute: () => DonorTransactionIdSignConsentRouteRoute,
  } as any)

const AuthenticatedSessionsSessionIdTestInfoRoute =
  AuthenticatedSessionsSessionIdTestInfoImport.update({
    id: '/test-info',
    path: '/test-info',
    getParentRoute: () => AuthenticatedSessionsSessionIdRouteRoute,
  } as any)

const AuthenticatedSessionsSessionIdSignedRoute =
  AuthenticatedSessionsSessionIdSignedImport.update({
    id: '/signed',
    path: '/signed',
    getParentRoute: () => AuthenticatedSessionsSessionIdRouteRoute,
  } as any)

const AuthenticatedSessionsSessionIdSignRoute =
  AuthenticatedSessionsSessionIdSignImport.update({
    id: '/sign',
    path: '/sign',
    getParentRoute: () => AuthenticatedSessionsSessionIdRouteRoute,
  } as any)

const AuthenticatedSessionsSessionIdSamplingRoute =
  AuthenticatedSessionsSessionIdSamplingImport.update({
    id: '/sampling',
    path: '/sampling',
    getParentRoute: () => AuthenticatedSessionsSessionIdRouteRoute,
  } as any)

const AuthenticatedSessionsSessionIdDonorInfoRoute =
  AuthenticatedSessionsSessionIdDonorInfoImport.update({
    id: '/donor-info',
    path: '/donor-info',
    getParentRoute: () => AuthenticatedSessionsSessionIdRouteRoute,
  } as any)

const AuthenticatedSessionsSessionIdCreatedRoute =
  AuthenticatedSessionsSessionIdCreatedImport.update({
    id: '/created',
    path: '/created',
    getParentRoute: () => AuthenticatedSessionsSessionIdRouteRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated': {
      id: '/_authenticated'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedImport
      parentRoute: typeof rootRoute
    }
    '/callback': {
      id: '/callback'
      path: '/callback'
      fullPath: '/callback'
      preLoaderRoute: typeof CallbackImport
      parentRoute: typeof rootRoute
    }
    '/donor/$transactionId': {
      id: '/donor/$transactionId'
      path: '/donor/$transactionId'
      fullPath: '/donor/$transactionId'
      preLoaderRoute: typeof DonorTransactionIdRouteImport
      parentRoute: typeof rootRoute
    }
    '/donor/success': {
      id: '/donor/success'
      path: '/donor/success'
      fullPath: '/donor/success'
      preLoaderRoute: typeof DonorSuccessImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated/sessions/$sessionId': {
      id: '/_authenticated/sessions/$sessionId'
      path: '/sessions/$sessionId'
      fullPath: '/sessions/$sessionId'
      preLoaderRoute: typeof AuthenticatedSessionsSessionIdRouteImport
      parentRoute: typeof AuthenticatedImport
    }
    '/donor/$transactionId/sign-consent': {
      id: '/donor/$transactionId/sign-consent'
      path: '/sign-consent'
      fullPath: '/donor/$transactionId/sign-consent'
      preLoaderRoute: typeof DonorTransactionIdSignConsentRouteImport
      parentRoute: typeof DonorTransactionIdRouteImport
    }
    '/donor/$transactionId/pin-code': {
      id: '/donor/$transactionId/pin-code'
      path: '/pin-code'
      fullPath: '/donor/$transactionId/pin-code'
      preLoaderRoute: typeof DonorTransactionIdPinCodeImport
      parentRoute: typeof DonorTransactionIdRouteImport
    }
    '/_authenticated/sessions/': {
      id: '/_authenticated/sessions/'
      path: '/sessions'
      fullPath: '/sessions'
      preLoaderRoute: typeof AuthenticatedSessionsIndexImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/sessions/$sessionId/created': {
      id: '/_authenticated/sessions/$sessionId/created'
      path: '/created'
      fullPath: '/sessions/$sessionId/created'
      preLoaderRoute: typeof AuthenticatedSessionsSessionIdCreatedImport
      parentRoute: typeof AuthenticatedSessionsSessionIdRouteImport
    }
    '/_authenticated/sessions/$sessionId/donor-info': {
      id: '/_authenticated/sessions/$sessionId/donor-info'
      path: '/donor-info'
      fullPath: '/sessions/$sessionId/donor-info'
      preLoaderRoute: typeof AuthenticatedSessionsSessionIdDonorInfoImport
      parentRoute: typeof AuthenticatedSessionsSessionIdRouteImport
    }
    '/_authenticated/sessions/$sessionId/sampling': {
      id: '/_authenticated/sessions/$sessionId/sampling'
      path: '/sampling'
      fullPath: '/sessions/$sessionId/sampling'
      preLoaderRoute: typeof AuthenticatedSessionsSessionIdSamplingImport
      parentRoute: typeof AuthenticatedSessionsSessionIdRouteImport
    }
    '/_authenticated/sessions/$sessionId/sign': {
      id: '/_authenticated/sessions/$sessionId/sign'
      path: '/sign'
      fullPath: '/sessions/$sessionId/sign'
      preLoaderRoute: typeof AuthenticatedSessionsSessionIdSignImport
      parentRoute: typeof AuthenticatedSessionsSessionIdRouteImport
    }
    '/_authenticated/sessions/$sessionId/signed': {
      id: '/_authenticated/sessions/$sessionId/signed'
      path: '/signed'
      fullPath: '/sessions/$sessionId/signed'
      preLoaderRoute: typeof AuthenticatedSessionsSessionIdSignedImport
      parentRoute: typeof AuthenticatedSessionsSessionIdRouteImport
    }
    '/_authenticated/sessions/$sessionId/test-info': {
      id: '/_authenticated/sessions/$sessionId/test-info'
      path: '/test-info'
      fullPath: '/sessions/$sessionId/test-info'
      preLoaderRoute: typeof AuthenticatedSessionsSessionIdTestInfoImport
      parentRoute: typeof AuthenticatedSessionsSessionIdRouteImport
    }
    '/donor/$transactionId/sign-consent/bank-id': {
      id: '/donor/$transactionId/sign-consent/bank-id'
      path: '/bank-id'
      fullPath: '/donor/$transactionId/sign-consent/bank-id'
      preLoaderRoute: typeof DonorTransactionIdSignConsentBankIdImport
      parentRoute: typeof DonorTransactionIdSignConsentRouteImport
    }
    '/donor/$transactionId/sign-consent/handwritten': {
      id: '/donor/$transactionId/sign-consent/handwritten'
      path: '/handwritten'
      fullPath: '/donor/$transactionId/sign-consent/handwritten'
      preLoaderRoute: typeof DonorTransactionIdSignConsentHandwrittenImport
      parentRoute: typeof DonorTransactionIdSignConsentRouteImport
    }
    '/donor/$transactionId/sign-consent/': {
      id: '/donor/$transactionId/sign-consent/'
      path: '/'
      fullPath: '/donor/$transactionId/sign-consent/'
      preLoaderRoute: typeof DonorTransactionIdSignConsentIndexImport
      parentRoute: typeof DonorTransactionIdSignConsentRouteImport
    }
  }
}

// Create and export the route tree

interface AuthenticatedSessionsSessionIdRouteRouteChildren {
  AuthenticatedSessionsSessionIdCreatedRoute: typeof AuthenticatedSessionsSessionIdCreatedRoute
  AuthenticatedSessionsSessionIdDonorInfoRoute: typeof AuthenticatedSessionsSessionIdDonorInfoRoute
  AuthenticatedSessionsSessionIdSamplingRoute: typeof AuthenticatedSessionsSessionIdSamplingRoute
  AuthenticatedSessionsSessionIdSignRoute: typeof AuthenticatedSessionsSessionIdSignRoute
  AuthenticatedSessionsSessionIdSignedRoute: typeof AuthenticatedSessionsSessionIdSignedRoute
  AuthenticatedSessionsSessionIdTestInfoRoute: typeof AuthenticatedSessionsSessionIdTestInfoRoute
}

const AuthenticatedSessionsSessionIdRouteRouteChildren: AuthenticatedSessionsSessionIdRouteRouteChildren =
  {
    AuthenticatedSessionsSessionIdCreatedRoute:
      AuthenticatedSessionsSessionIdCreatedRoute,
    AuthenticatedSessionsSessionIdDonorInfoRoute:
      AuthenticatedSessionsSessionIdDonorInfoRoute,
    AuthenticatedSessionsSessionIdSamplingRoute:
      AuthenticatedSessionsSessionIdSamplingRoute,
    AuthenticatedSessionsSessionIdSignRoute:
      AuthenticatedSessionsSessionIdSignRoute,
    AuthenticatedSessionsSessionIdSignedRoute:
      AuthenticatedSessionsSessionIdSignedRoute,
    AuthenticatedSessionsSessionIdTestInfoRoute:
      AuthenticatedSessionsSessionIdTestInfoRoute,
  }

const AuthenticatedSessionsSessionIdRouteRouteWithChildren =
  AuthenticatedSessionsSessionIdRouteRoute._addFileChildren(
    AuthenticatedSessionsSessionIdRouteRouteChildren,
  )

interface AuthenticatedRouteChildren {
  AuthenticatedSessionsSessionIdRouteRoute: typeof AuthenticatedSessionsSessionIdRouteRouteWithChildren
  AuthenticatedSessionsIndexRoute: typeof AuthenticatedSessionsIndexRoute
}

const AuthenticatedRouteChildren: AuthenticatedRouteChildren = {
  AuthenticatedSessionsSessionIdRouteRoute:
    AuthenticatedSessionsSessionIdRouteRouteWithChildren,
  AuthenticatedSessionsIndexRoute: AuthenticatedSessionsIndexRoute,
}

const AuthenticatedRouteWithChildren = AuthenticatedRoute._addFileChildren(
  AuthenticatedRouteChildren,
)

interface DonorTransactionIdSignConsentRouteRouteChildren {
  DonorTransactionIdSignConsentBankIdRoute: typeof DonorTransactionIdSignConsentBankIdRoute
  DonorTransactionIdSignConsentHandwrittenRoute: typeof DonorTransactionIdSignConsentHandwrittenRoute
  DonorTransactionIdSignConsentIndexRoute: typeof DonorTransactionIdSignConsentIndexRoute
}

const DonorTransactionIdSignConsentRouteRouteChildren: DonorTransactionIdSignConsentRouteRouteChildren =
  {
    DonorTransactionIdSignConsentBankIdRoute:
      DonorTransactionIdSignConsentBankIdRoute,
    DonorTransactionIdSignConsentHandwrittenRoute:
      DonorTransactionIdSignConsentHandwrittenRoute,
    DonorTransactionIdSignConsentIndexRoute:
      DonorTransactionIdSignConsentIndexRoute,
  }

const DonorTransactionIdSignConsentRouteRouteWithChildren =
  DonorTransactionIdSignConsentRouteRoute._addFileChildren(
    DonorTransactionIdSignConsentRouteRouteChildren,
  )

interface DonorTransactionIdRouteRouteChildren {
  DonorTransactionIdSignConsentRouteRoute: typeof DonorTransactionIdSignConsentRouteRouteWithChildren
  DonorTransactionIdPinCodeRoute: typeof DonorTransactionIdPinCodeRoute
}

const DonorTransactionIdRouteRouteChildren: DonorTransactionIdRouteRouteChildren =
  {
    DonorTransactionIdSignConsentRouteRoute:
      DonorTransactionIdSignConsentRouteRouteWithChildren,
    DonorTransactionIdPinCodeRoute: DonorTransactionIdPinCodeRoute,
  }

const DonorTransactionIdRouteRouteWithChildren =
  DonorTransactionIdRouteRoute._addFileChildren(
    DonorTransactionIdRouteRouteChildren,
  )

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof AuthenticatedRouteWithChildren
  '/callback': typeof CallbackRoute
  '/donor/$transactionId': typeof DonorTransactionIdRouteRouteWithChildren
  '/donor/success': typeof DonorSuccessRoute
  '/sessions/$sessionId': typeof AuthenticatedSessionsSessionIdRouteRouteWithChildren
  '/donor/$transactionId/sign-consent': typeof DonorTransactionIdSignConsentRouteRouteWithChildren
  '/donor/$transactionId/pin-code': typeof DonorTransactionIdPinCodeRoute
  '/sessions': typeof AuthenticatedSessionsIndexRoute
  '/sessions/$sessionId/created': typeof AuthenticatedSessionsSessionIdCreatedRoute
  '/sessions/$sessionId/donor-info': typeof AuthenticatedSessionsSessionIdDonorInfoRoute
  '/sessions/$sessionId/sampling': typeof AuthenticatedSessionsSessionIdSamplingRoute
  '/sessions/$sessionId/sign': typeof AuthenticatedSessionsSessionIdSignRoute
  '/sessions/$sessionId/signed': typeof AuthenticatedSessionsSessionIdSignedRoute
  '/sessions/$sessionId/test-info': typeof AuthenticatedSessionsSessionIdTestInfoRoute
  '/donor/$transactionId/sign-consent/bank-id': typeof DonorTransactionIdSignConsentBankIdRoute
  '/donor/$transactionId/sign-consent/handwritten': typeof DonorTransactionIdSignConsentHandwrittenRoute
  '/donor/$transactionId/sign-consent/': typeof DonorTransactionIdSignConsentIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof AuthenticatedRouteWithChildren
  '/callback': typeof CallbackRoute
  '/donor/$transactionId': typeof DonorTransactionIdRouteRouteWithChildren
  '/donor/success': typeof DonorSuccessRoute
  '/sessions/$sessionId': typeof AuthenticatedSessionsSessionIdRouteRouteWithChildren
  '/donor/$transactionId/pin-code': typeof DonorTransactionIdPinCodeRoute
  '/sessions': typeof AuthenticatedSessionsIndexRoute
  '/sessions/$sessionId/created': typeof AuthenticatedSessionsSessionIdCreatedRoute
  '/sessions/$sessionId/donor-info': typeof AuthenticatedSessionsSessionIdDonorInfoRoute
  '/sessions/$sessionId/sampling': typeof AuthenticatedSessionsSessionIdSamplingRoute
  '/sessions/$sessionId/sign': typeof AuthenticatedSessionsSessionIdSignRoute
  '/sessions/$sessionId/signed': typeof AuthenticatedSessionsSessionIdSignedRoute
  '/sessions/$sessionId/test-info': typeof AuthenticatedSessionsSessionIdTestInfoRoute
  '/donor/$transactionId/sign-consent/bank-id': typeof DonorTransactionIdSignConsentBankIdRoute
  '/donor/$transactionId/sign-consent/handwritten': typeof DonorTransactionIdSignConsentHandwrittenRoute
  '/donor/$transactionId/sign-consent': typeof DonorTransactionIdSignConsentIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_authenticated': typeof AuthenticatedRouteWithChildren
  '/callback': typeof CallbackRoute
  '/donor/$transactionId': typeof DonorTransactionIdRouteRouteWithChildren
  '/donor/success': typeof DonorSuccessRoute
  '/_authenticated/sessions/$sessionId': typeof AuthenticatedSessionsSessionIdRouteRouteWithChildren
  '/donor/$transactionId/sign-consent': typeof DonorTransactionIdSignConsentRouteRouteWithChildren
  '/donor/$transactionId/pin-code': typeof DonorTransactionIdPinCodeRoute
  '/_authenticated/sessions/': typeof AuthenticatedSessionsIndexRoute
  '/_authenticated/sessions/$sessionId/created': typeof AuthenticatedSessionsSessionIdCreatedRoute
  '/_authenticated/sessions/$sessionId/donor-info': typeof AuthenticatedSessionsSessionIdDonorInfoRoute
  '/_authenticated/sessions/$sessionId/sampling': typeof AuthenticatedSessionsSessionIdSamplingRoute
  '/_authenticated/sessions/$sessionId/sign': typeof AuthenticatedSessionsSessionIdSignRoute
  '/_authenticated/sessions/$sessionId/signed': typeof AuthenticatedSessionsSessionIdSignedRoute
  '/_authenticated/sessions/$sessionId/test-info': typeof AuthenticatedSessionsSessionIdTestInfoRoute
  '/donor/$transactionId/sign-consent/bank-id': typeof DonorTransactionIdSignConsentBankIdRoute
  '/donor/$transactionId/sign-consent/handwritten': typeof DonorTransactionIdSignConsentHandwrittenRoute
  '/donor/$transactionId/sign-consent/': typeof DonorTransactionIdSignConsentIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | ''
    | '/callback'
    | '/donor/$transactionId'
    | '/donor/success'
    | '/sessions/$sessionId'
    | '/donor/$transactionId/sign-consent'
    | '/donor/$transactionId/pin-code'
    | '/sessions'
    | '/sessions/$sessionId/created'
    | '/sessions/$sessionId/donor-info'
    | '/sessions/$sessionId/sampling'
    | '/sessions/$sessionId/sign'
    | '/sessions/$sessionId/signed'
    | '/sessions/$sessionId/test-info'
    | '/donor/$transactionId/sign-consent/bank-id'
    | '/donor/$transactionId/sign-consent/handwritten'
    | '/donor/$transactionId/sign-consent/'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/callback'
    | '/donor/$transactionId'
    | '/donor/success'
    | '/sessions/$sessionId'
    | '/donor/$transactionId/pin-code'
    | '/sessions'
    | '/sessions/$sessionId/created'
    | '/sessions/$sessionId/donor-info'
    | '/sessions/$sessionId/sampling'
    | '/sessions/$sessionId/sign'
    | '/sessions/$sessionId/signed'
    | '/sessions/$sessionId/test-info'
    | '/donor/$transactionId/sign-consent/bank-id'
    | '/donor/$transactionId/sign-consent/handwritten'
    | '/donor/$transactionId/sign-consent'
  id:
    | '__root__'
    | '/'
    | '/_authenticated'
    | '/callback'
    | '/donor/$transactionId'
    | '/donor/success'
    | '/_authenticated/sessions/$sessionId'
    | '/donor/$transactionId/sign-consent'
    | '/donor/$transactionId/pin-code'
    | '/_authenticated/sessions/'
    | '/_authenticated/sessions/$sessionId/created'
    | '/_authenticated/sessions/$sessionId/donor-info'
    | '/_authenticated/sessions/$sessionId/sampling'
    | '/_authenticated/sessions/$sessionId/sign'
    | '/_authenticated/sessions/$sessionId/signed'
    | '/_authenticated/sessions/$sessionId/test-info'
    | '/donor/$transactionId/sign-consent/bank-id'
    | '/donor/$transactionId/sign-consent/handwritten'
    | '/donor/$transactionId/sign-consent/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AuthenticatedRoute: typeof AuthenticatedRouteWithChildren
  CallbackRoute: typeof CallbackRoute
  DonorTransactionIdRouteRoute: typeof DonorTransactionIdRouteRouteWithChildren
  DonorSuccessRoute: typeof DonorSuccessRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AuthenticatedRoute: AuthenticatedRouteWithChildren,
  CallbackRoute: CallbackRoute,
  DonorTransactionIdRouteRoute: DonorTransactionIdRouteRouteWithChildren,
  DonorSuccessRoute: DonorSuccessRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_authenticated",
        "/callback",
        "/donor/$transactionId",
        "/donor/success"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_authenticated": {
      "filePath": "_authenticated.tsx",
      "children": [
        "/_authenticated/sessions/$sessionId",
        "/_authenticated/sessions/"
      ]
    },
    "/callback": {
      "filePath": "callback.tsx"
    },
    "/donor/$transactionId": {
      "filePath": "donor/$transactionId/route.tsx",
      "children": [
        "/donor/$transactionId/sign-consent",
        "/donor/$transactionId/pin-code"
      ]
    },
    "/donor/success": {
      "filePath": "donor/success.tsx"
    },
    "/_authenticated/sessions/$sessionId": {
      "filePath": "_authenticated/sessions/$sessionId/route.tsx",
      "parent": "/_authenticated",
      "children": [
        "/_authenticated/sessions/$sessionId/created",
        "/_authenticated/sessions/$sessionId/donor-info",
        "/_authenticated/sessions/$sessionId/sampling",
        "/_authenticated/sessions/$sessionId/sign",
        "/_authenticated/sessions/$sessionId/signed",
        "/_authenticated/sessions/$sessionId/test-info"
      ]
    },
    "/donor/$transactionId/sign-consent": {
      "filePath": "donor/$transactionId/sign-consent/route.tsx",
      "parent": "/donor/$transactionId",
      "children": [
        "/donor/$transactionId/sign-consent/bank-id",
        "/donor/$transactionId/sign-consent/handwritten",
        "/donor/$transactionId/sign-consent/"
      ]
    },
    "/donor/$transactionId/pin-code": {
      "filePath": "donor/$transactionId/pin-code.tsx",
      "parent": "/donor/$transactionId"
    },
    "/_authenticated/sessions/": {
      "filePath": "_authenticated/sessions/index.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/sessions/$sessionId/created": {
      "filePath": "_authenticated/sessions/$sessionId/created.tsx",
      "parent": "/_authenticated/sessions/$sessionId"
    },
    "/_authenticated/sessions/$sessionId/donor-info": {
      "filePath": "_authenticated/sessions/$sessionId/donor-info.tsx",
      "parent": "/_authenticated/sessions/$sessionId"
    },
    "/_authenticated/sessions/$sessionId/sampling": {
      "filePath": "_authenticated/sessions/$sessionId/sampling.tsx",
      "parent": "/_authenticated/sessions/$sessionId"
    },
    "/_authenticated/sessions/$sessionId/sign": {
      "filePath": "_authenticated/sessions/$sessionId/sign.tsx",
      "parent": "/_authenticated/sessions/$sessionId"
    },
    "/_authenticated/sessions/$sessionId/signed": {
      "filePath": "_authenticated/sessions/$sessionId/signed.tsx",
      "parent": "/_authenticated/sessions/$sessionId"
    },
    "/_authenticated/sessions/$sessionId/test-info": {
      "filePath": "_authenticated/sessions/$sessionId/test-info.tsx",
      "parent": "/_authenticated/sessions/$sessionId"
    },
    "/donor/$transactionId/sign-consent/bank-id": {
      "filePath": "donor/$transactionId/sign-consent/bank-id.tsx",
      "parent": "/donor/$transactionId/sign-consent"
    },
    "/donor/$transactionId/sign-consent/handwritten": {
      "filePath": "donor/$transactionId/sign-consent/handwritten.tsx",
      "parent": "/donor/$transactionId/sign-consent"
    },
    "/donor/$transactionId/sign-consent/": {
      "filePath": "donor/$transactionId/sign-consent/index.tsx",
      "parent": "/donor/$transactionId/sign-consent"
    }
  }
}
ROUTE_MANIFEST_END */
