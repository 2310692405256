import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/donor/$transactionId/sign-consent')({
  beforeLoad: async ({ context }) => {
    if (!context.remoteSigning) {
      throw redirect({
        from: '/donor/$transactionId/sign-consent',
        to: '../pin-code',
      });
    }
    return { remoteSigning: context.remoteSigning };
  },
});
