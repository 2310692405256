import { z } from 'zod';
import { IsoDateTimeSchema, ReferenceSchema } from '../fhir-entities';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

export const SpecimenDiscardedInLabEventSchema = BaseEventSchema.extend({
  eventType: z.literal('SpecimenDiscardedInLabEvent'),
  eventData: z.object({
    /**
     * Unique identifier for the specimen.
     */
    specimenId: z.string().uuid(),
    /**
     * When the specimen was discarded.
     */
    discardedAt: IsoDateTimeSchema,
    /**
     * {@link Practitioner} who discarded the specimen.
     */
    practitioner: ReferenceSchema(z.literal('Practitioner')),
  }),
});

/**
 * Specimen was discarded in the lab.
 */
export class SpecimenDiscardedInLabEvent extends createSchemaClass(
  SpecimenDiscardedInLabEventSchema,
) {}
