import { Button } from '@careos/react-ui/Button';
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from '@careos/react-ui/Dialog';
import { useTranslation } from 'react-i18next';

import { SignaturePad } from './signature-pad';

type SignaturPadModalProps = {
  onSubmitSignature: (signature: string) => void;
  loading?: boolean;
};

export const SignaturePadModal = (props: SignaturPadModalProps) => {
  const { t } = useTranslation();

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="w-full py-8 sm:whitespace-nowrap">
          {t('requisition_form.signing.sign_by_hand')}
        </Button>
      </DialogTrigger>
      <DialogContent className="h-auto w-fit max-w-none p-8">
        <DialogTitle>{t('requisition_form.signing.signature_modal.title')}</DialogTitle>
        <SignaturePad {...props} />
      </DialogContent>
    </Dialog>
  );
};
