import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { substances, bankidMessages, generic } from '@careos/translations';
import { isDev } from '#utils/environment';

export const resources = {
  sv: {
    translation: {
      ...{ substances: substances.sv, bankid: bankidMessages.sv.bankid },
      ...generic.sv,
    },
  },
  en: {
    translation: {
      ...{ substances: substances.en, bankid: bankidMessages.en.bankid },
      ...generic.en,
    },
  },
} as const;

i18n.use(initReactI18next).init({
  lng: 'sv',
  debug: isDev,
  returnNull: false,
  defaultNS: 'translation',
  resources,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
