import { z } from 'zod';

import { useTranslation } from 'react-i18next';
import { InputField, type InputFieldBaseProps } from './InputField';
import type { FieldValues } from 'react-hook-form';

export const FirstNameSchema = z
  .string()
  .min(2, 'Name must be at least 2 characters long');

export const FirstNameField = <T extends FieldValues>({
  ...props
}: InputFieldBaseProps<T>) => {
  const { t } = useTranslation();

  return <InputField label={t('generic.form.firstName.label')} {...props} />;
};
