import { nonEmptyString } from '@careos/types';
import { z } from 'zod';

export const RandomizationListUploadDtoSchema = z.object({
  content: z.string().base64().min(1),
  originalFileName: nonEmptyString,
  mimeType: nonEmptyString,
  encryptedToken: nonEmptyString,
});

export type RandomizationListUploadDto = z.infer<
  typeof RandomizationListUploadDtoSchema
>;
