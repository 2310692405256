import {
  OrganizationPolicyIdentifier,
  ReasonForTestingOrOtherEnum,
  TTLSecSchema,
} from '@abc-labs-ab/ts-events';
import { z } from 'zod';

export const UpdateOrganizationAcessPolicyRequestDtoSchema = z.object({
  accessPolicy: z.enum([
    OrganizationPolicyIdentifier.AccessRequisition,
    OrganizationPolicyIdentifier.AccessResultNeg,
    OrganizationPolicyIdentifier.AccessResultPos,
  ]),
  reasonForTesting: ReasonForTestingOrOtherEnum,
  ttlSec: TTLSecSchema,
  userId: z.string(),
});

export type UpdateOrganizationAccessPolicyRequestDto = z.infer<
  typeof UpdateOrganizationAcessPolicyRequestDtoSchema
>;
