import { z } from 'zod';
import {
  ReasonsForTestingEnum,
  ReasonForTestingOrOtherEnum,
} from '@abc-labs-ab/ts-events';

export type SnomedCode = string;

export const OrderReasonSchema = ReasonsForTestingEnum.extract([
  'random_selection',
  'new_employee',
  'incident_accident',
]);

export const ReasonsForTestingMap = new Map<SnomedCode, ReasonsForTesting>([
  ['255226008', 'random_selection'],
  ['79079000', 'new_employee'],
  ['275926002', 'health_check'],
  ['78092008', 'rehabilitation'],
  ['17542004', 'incident_accident'],
  ['405644001', 'incident_accident'],
]);

export const OtherReasonSnomedCode = '74964007';

export const OtherReasonSchema = z.object({
  otherReason: z.string(),
});

export const ReasonSchema = z.union([ReasonsForTestingEnum, OtherReasonSchema]);
export type Reason = z.infer<typeof ReasonSchema>;

export const ReasonForTesting = ReasonsForTestingEnum.Enum;
export const ReasonForTestingValues = ReasonsForTestingEnum.Values;
export type ReasonsForTesting = z.infer<typeof ReasonsForTestingEnum>;

export const ReasonForTestingOrOther = ReasonForTestingOrOtherEnum.Enum;
export const ReasonForTestingOrOtherValues = ReasonForTestingOrOtherEnum.Values;
export type ReasonForTestingOrOther = z.infer<
  typeof ReasonForTestingOrOtherEnum
>;

export type OrderReason = z.infer<typeof OrderReasonSchema>;
export const OrderReason = OrderReasonSchema.Enum;

export type OtherReason = z.infer<typeof OtherReasonSchema>;

export const isSnomedCodeForRandomTesting = (code: SnomedCode) =>
  ReasonsForTestingMap.get(code) === ReasonForTesting.random_selection;

export const isReasonForRandomTesting = (reason: string) =>
  reason === ReasonForTesting.random_selection;
