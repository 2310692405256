/// <reference types="vite/client" />

import * as Sentry from '@sentry/react';
import type { BrowserOptions } from '@sentry/react';
import { isDev, environment } from '#utils/environment';

type SentryOptions = Omit<BrowserOptions, 'router'>;

/**
 * Initialize Sentry with the given router and options.
 * Will use the environment and DSN from the import.meta.env if not provided in options.
 * Preferably have VITE_SENTRY_DSN set in your .env file.
 * @param router tanstackRouter instance
 * @param options
 */
export function initializeSentry(router: unknown, options: SentryOptions = {}) {
  const { dsn: optionsDsn, environment: optionsEnvironment, ...rest } = options;
  const dsn = optionsDsn ?? import.meta.env.VITE_SENTRY_DSN ?? '';

  if (!dsn) {
    console.warn('No Sentry DSN provided, skipping Sentry initialization');
    return;
  }

  Sentry.init({
    dsn: dsn ?? import.meta.env.VITE_SENTRY_DSN,
    environment: optionsEnvironment ?? environment,
    integrations: [
      Sentry.replayIntegration(),
      Sentry.tanstackRouterBrowserTracingIntegration(router),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: isDev ? 0 : 0.1,
    replaysOnErrorSampleRate: isDev ? 0 : 1.0,
    beforeSend(event) {
      if (isDev) {
        console.error(event);
        return null; // Drop the event in development
      }
      return event;
    },
    ...rest,
  });
}
