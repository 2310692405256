import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@careos/react-ui/AlertDialog';
import { useToast } from '@careos/react-ui/Toast';
import type { SamplingSession } from '@careos/toxicology-types';
import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCreateFinishSession } from '@/features/order/table/api/create-finish-sampling-session';
import { useCreateStartSamplingSession } from '@/features/order/table/api/create-start-sampling-session';
import { getSessionsQueryOptions } from '@/features/order/table/api/get-sampling-sessions';
import { getSessionColumns } from '@/features/order/table/components/columns';
import { DataTable } from '@/features/order/table/components/data-table';
import { NumberGenerator } from '@/features/order/table/components/number-generator';
import { assertDefined } from '@/utils/assert-defined';

export const Route = createFileRoute('/_authenticated/sessions/')({
  loader: async ({ context }) => {
    await context.queryClient.ensureQueryData(getSessionsQueryOptions());
  },
  component: SamplingSessionsComponent,
});

function SamplingSessionsComponent() {
  const navigate = Route.useNavigate();
  const { toast } = useToast();
  const { t } = useTranslation('translation', { keyPrefix: 'table' });
  const [finishSessionModalOpen, setFinishSessionModalOpen] = useState(false);
  const [isNumberGeneratorModalOpen, setIsNumberGeneratorModalOpen] = useState(false);
  const [currentSessionId, setCurrentSessionId] = useState<SamplingSession['sessionId'] | null>(
    null,
  );

  const { data } = useSuspenseQuery(getSessionsQueryOptions());

  const createStartSamplingSessionMutation = useCreateStartSamplingSession({
    mutationConfig: {
      onSuccess: ({ sessionId }) =>
        navigate({
          to: '/sessions/$sessionId/test-info',
          params: { sessionId },
        }),
    },
  });
  const createFinishSamplingSessionMutation = useCreateFinishSession({
    mutationConfig: {
      onSuccess: () => {
        toast({
          description: t('finish_session_dialog.toast_message.success'),
        });
      },
      onError: () => {
        toast({
          variant: 'destructive',
          description: t('finish_session_dialog.toast_message.error'),
        });
      },
    },
  });

  const handleStartSession = useCallback(
    (sessionId: string) =>
      createStartSamplingSessionMutation.mutate({
        sessionId,
      }),
    [createStartSamplingSessionMutation],
  );

  const handleFinishSession = useCallback((sessionId: string) => {
    setCurrentSessionId(sessionId);
    setFinishSessionModalOpen(true);
  }, []);

  const handleClickFinishSamplingSession = () => {
    createFinishSamplingSessionMutation.mutate({
      sessionId: assertDefined(currentSessionId),
    });
  };

  const handleCloseFinishSessionModal = () => {
    setCurrentSessionId(null);
    setFinishSessionModalOpen(false);
  };

  const handleOpenNumberGenerator = useCallback((sessionId: string) => {
    setCurrentSessionId(sessionId);
    setIsNumberGeneratorModalOpen(true);
  }, []);

  const handleCloseNumberGenerator = () => {
    setCurrentSessionId(null);
    setIsNumberGeneratorModalOpen(false);
  };

  const sessionColumns = useMemo(
    () =>
      getSessionColumns({
        onStartSession: handleStartSession,
        onFinishSession: handleFinishSession,
        onOpenNumberGenerator: handleOpenNumberGenerator,
      }),
    [handleStartSession, handleFinishSession, handleOpenNumberGenerator],
  );

  const currentSession = data.samplingSessions.find((it) => it.sessionId === currentSessionId);

  return (
    <div className="container mx-auto py-10">
      {currentSession && (
        <NumberGenerator
          session={currentSession}
          isGeneratorDialogOpen={isNumberGeneratorModalOpen}
          onOpenChange={handleCloseNumberGenerator}
        />
      )}
      <AlertDialog open={finishSessionModalOpen} onOpenChange={handleCloseFinishSessionModal}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{t('finish_session_dialog.title')}</AlertDialogTitle>
            <AlertDialogDescription>
              {t('finish_session_dialog.description')}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>{t('finish_session_dialog.cancel')}</AlertDialogCancel>
            <AlertDialogAction onClick={handleClickFinishSamplingSession}>
              {t('finish_session_dialog.confirm')}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <DataTable columns={sessionColumns} data={data.samplingSessions} />
    </div>
  );
}
