import { TestType } from '@careos/organization-api-types';
import { Card, CardContent, CardHeader, CardTitle } from '@careos/react-ui/Card';
import type { DoaPanelSize, SamplingSession } from '@careos/toxicology-types';
import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { getSessionOrderOptionsQueryOptions } from '@/features/order/form/api/get-order-options';
import { getSessionQueryOptions } from '@/features/order/form/api/get-sampling-session';
import { RequisitionFormTestInfo } from '@/features/order/form/components/requisition-form-test-info';
import { useCollectionStore } from '@/features/order/form/stores/collection-store';

export const Route = createFileRoute('/_authenticated/sessions/$sessionId/test-info')({
  loader: async ({ context, params }) => {
    await Promise.all([
      context.queryClient.ensureQueryData(getSessionOrderOptionsQueryOptions()),
      context.queryClient.ensureQueryData(getSessionQueryOptions(params.sessionId)),
    ]);
  },
  component: RouteComponent,
});

function RouteComponent() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'requisition_form.test_info',
  });
  const navigate = Route.useNavigate();
  const params = Route.useParams();
  const testInfoForm = useCollectionStore((state) => state.testInfoForm);
  const updateTestInfoForm = useCollectionStore((state) => state.updateTestInfoForm);

  const orderOptionsQuery = useSuspenseQuery(getSessionOrderOptionsQueryOptions());
  const samplingSessionQuery = useSuspenseQuery(getSessionQueryOptions(params.sessionId));

  const handleTestInfoUpdate = (formValues: RequisitionFormTestInfo) => {
    updateTestInfoForm(formValues);
    navigate({ to: '../donor-info' });
  };

  const employers = orderOptionsQuery.data.employers;
  const samplingSession = samplingSessionQuery.data.samplingSession;

  const initialEmployer = employers.find((it) => it.key === samplingSession.employer.key);

  if (!initialEmployer) {
    throw new Error('Invalid employer key');
  }

  const defaultValues = testInfoForm ?? {
    testInfo: getInitialFormSamplingDetails(samplingSession.samplingDetails),
    samplingLocation: initialEmployer,
    orderReason: samplingSession.reasonForTesting,
  };

  return (
    <Card className="mx-auto mt-8 w-fit">
      <CardHeader>
        <CardTitle className="mx-auto">{t('title')}</CardTitle>
      </CardHeader>
      <CardContent>
        <RequisitionFormTestInfo
          formVals={defaultValues}
          onUpdate={handleTestInfoUpdate}
          {...orderOptionsQuery.data}
          samplingSession={samplingSessionQuery.data.samplingSession}
        />
      </CardContent>
    </Card>
  );
}

const getInitialFormSamplingDetails = (
  samplingDetails: SamplingSession['samplingDetails'],
): RequisitionFormTestInfo['testInfo'] => {
  const doaSamplingDetails = samplingDetails.find((it) => it.testType === 'DoA');

  if (doaSamplingDetails) {
    const defaultValues: RequisitionFormTestInfo['testInfo'] = {
      testType: doaSamplingDetails.testType,
      samplingKit: doaSamplingDetails.samplingKit,
      sampleType: doaSamplingDetails.sampleType,
      panelSize: doaSamplingDetails.panelSize as Exclude<DoaPanelSize, 'CHIRAL'>,
      additionalSubstances: [],
    };

    if (defaultValues.testType === 'DoA') {
      defaultValues.additionalSubstances = doaSamplingDetails.additionalSubstances || [];
    }

    return defaultValues;
  }

  return {
    testType: TestType.PEth,
  };
};
