import { Button } from '@careos/react-ui/Button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@careos/react-ui/Dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@careos/react-ui/Form';
import { Input } from '@careos/react-ui/Input';
import { GenerateRandomNumberSeriesRequestDtoSchema } from '@careos/toxicology-types';
import type { SamplingSession } from '@careos/toxicology-types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { useRandomSamplingNumbers } from '../api/create-random-sampling-numbers-series';

type Props = {
  session: SamplingSession;
  isGeneratorDialogOpen: boolean;
  onOpenChange: (open: boolean) => void;
};

const FormSchema = GenerateRandomNumberSeriesRequestDtoSchema;

export const NumberGenerator = ({ session, isGeneratorDialogOpen, onOpenChange }: Props) => {
  const { sessionId, generatedNumberSeries } = session;

  const { t } = useTranslation('translation', {
    keyPrefix: 'number_generator',
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      amountToGenerate: 0,
      maxNumber: 0,
    },
  });

  const {
    mutate: generateNumbers,
    isPending,
    status,
    error,
    reset: resetNumbersResponse,
  } = useRandomSamplingNumbers({
    mutationConfig: {
      onSuccess: () => {
        form.reset();
      },
    },
  });

  const onSubmit = (values: z.infer<typeof FormSchema>) => {
    generateNumbers({
      sessionId,
      body: {
        maxNumber: values.maxNumber,
        amountToGenerate: values.amountToGenerate,
      },
    });
  };

  const generateBtnText = generatedNumberSeries ? t('regenerate') : t('generate');

  const handleClose = () => {
    resetNumbersResponse();
    onOpenChange(false);
  };

  return (
    <Dialog open={isGeneratorDialogOpen} onOpenChange={handleClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('title')}</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <FormField
              control={form.control}
              name="maxNumber"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('employees_on_site')}</FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      {...field}
                      onChange={(event) =>
                        field.onChange(event.target.value ? Number(event.target.value) : '')
                      }
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="amountToGenerate"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('employees_to_test')}</FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      {...field}
                      onChange={(event) =>
                        field.onChange(event.target.value ? Number(event.target.value) : '')
                      }
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button disabled={isPending}>{generateBtnText}</Button>
          </form>
        </Form>
        <div>
          {status === 'error' && (
            <p className="text-red-500">
              {t('error')}: {error.message}
            </p>
          )}
          {Boolean(generatedNumberSeries) && status !== 'error' && (
            <p className="rounded bg-slate-100 p-2">{generatedNumberSeries}</p>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
