import { z } from 'zod';
import { IdentifierSchema, IsoDateTimeSchema } from '../fhir-entities';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';
import { BasePolicySchema } from './organization-policy-updated-event';

export const PolicyManagedRequisitionSchema = BasePolicySchema.extend({
  /**
   * Organization access policies are applied to either requisitions or results.
   * Requisitions can create without results, but not the other way around.
   * Hence, exemptions are made on requisitions rather than results.
   */
  requisitionId: z.string(),
});

export const OrganizationPolicyExemptedEventSchema = BaseEventSchema.extend({
  eventType: z.literal('OrganizationPolicyExemptedEvent'),
  eventData: z.object({
    /**
     * The target of exemption, including which type of policy it is
     * exempting the entity from.
     */
    target: PolicyManagedRequisitionSchema,
    /**
     * Reference to the user who took the action.
     */
    user: IdentifierSchema,
    /**
     * Timestamp of the action.
     */
    registeredAt: IsoDateTimeSchema,
  }),
});

/**
 * This event is triggered when an entity within an organization should be
 * exempted from the organization's access policy limits.
 */
export class OrganizationPolicyExemptedEvent extends createSchemaClass(
  OrganizationPolicyExemptedEventSchema,
) {}
