import { z } from 'zod';
import {
  AnnotationSchema,
  IdentifierSchema,
  IsoDateSchema,
  IsoDateTimeSchema,
  PatientSchema,
  ReferenceSchema,
} from '../fhir-entities';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

export const SamplingSessionDetailsSchema = z.object({
  /**
   * Test type that is being ordered. eg DoA, PEth.
   */
  testType: z.string(),
  /**
   * Sample type that is being ordered. eg urine, blood, saliva.
   */
  sampleType: ReferenceSchema(z.literal('ActivityDefinition')),
  /**
   * The equipment used to collect the sample.
   */
  samplingKit: IdentifierSchema.optional(),
  /**
   * The panel size that the sample should be tested for.
   */
  panelSize: z.string(),
  /**
   * Additional substances that the sample should be tested for.
   */
  additionalSubstances: z.array(z.string()).optional(),
});

export type SamplingSessionDetails = z.infer<
  typeof SamplingSessionDetailsSchema
>;

export const BaseCollectorAssignmentOrderSchema = z.object({
  /**
   * When the sampling should be performed.
   */
  samplingDate: IsoDateSchema,
  /**
   * Time when the sampling should be performed.
   * Can be an exact time (e.g. 10:00) or a time range (e.g. 10:00-12:00).
   */
  timeSlot: z.string(),
  /**
   * The projected number of samples to be collected.
   */
  sampleCount: z.number(),
  /**
   * The testing orders that are being requested.
   */
  samplingDetails: z.array(SamplingSessionDetailsSchema),
  /**
   * The requested type of interaction between the donor and collector during sampling. {@link DonorInteractionType}.
   */
  donorInteractionType: z.string().optional(),
});
export const RandomCollectorAssignmentOrderSchema =
  BaseCollectorAssignmentOrderSchema.extend({
    // For now, this object is empty because it's identical to BaseCollectorAssignmentOrderSchema
    // In the future, properties that are specific to RandomCollectorAssignmentOrderSchema can be added here.
  });

export type RandomCollectorAssignmentOrder = z.infer<
  typeof RandomCollectorAssignmentOrderSchema
>;

export const DonorCollectorAssignmentOrderSchema =
  BaseCollectorAssignmentOrderSchema.extend({
    /**
     * The donor that the order is for.
     */
    donor: PatientSchema,
  });

export type DonorCollectorAssignmentOrder = z.infer<
  typeof DonorCollectorAssignmentOrderSchema
>;
export const isDonorCollectorAssignmentOrder = (
  order: unknown,
): order is DonorCollectorAssignmentOrder =>
  DonorCollectorAssignmentOrderSchema.safeParse(order).success;

export const CollectorAssignmentOrderSchema = z.union([
  DonorCollectorAssignmentOrderSchema,
  RandomCollectorAssignmentOrderSchema,
]);
export type CollectorAssignmentOrder = z.infer<
  typeof CollectorAssignmentOrderSchema
>;

export const isRandomCollectorAssignmentOrder = (
  order: unknown,
): order is RandomCollectorAssignmentOrder =>
  RandomCollectorAssignmentOrderSchema.safeParse(order).success;

export const SamplingSessionRequestedEventSchema = BaseEventSchema.extend({
  eventType: z.literal('SamplingSessionRequestedEvent'),
  eventData: z.object({
    /**
     * Unique identifier for the session.
     */
    id: z.string().uuid(),
    /**
     * Practitioner who requested the session.
     */
    requester: ReferenceSchema(z.literal('Practitioner')),
    /**
     * When the session was requested.
     */
    createdAt: IsoDateTimeSchema,
    /**
     * Practitioner who will collect the sample(s) for this session.
     */
    practitioner: ReferenceSchema(z.literal('Practitioner')),
    /**
     * Any notes or comment made about the request of a session.
     */
    notes: AnnotationSchema.optional(),
    /**
     * Unique identifier for the order for which the session is requested for.
     */
    orderId: z.string().uuid(),
    /**
     * The order for which the session is requested for.
     */
    collectorAssignmentOrder: CollectorAssignmentOrderSchema,
  }),
});

/**
 * A sampling session has been requested.
 */
export class SamplingSessionRequestedEvent extends createSchemaClass(
  SamplingSessionRequestedEventSchema,
) {}
