import { z } from 'zod';

export const ReasonsForTestingEnum = z.enum([
  'random_selection',
  'new_employee',
  'health_check',
  'rehabilitation',
  'incident_accident',
]);

export const ReasonForTestingOrOtherEnum = z.enum([
  ...ReasonsForTestingEnum.options,
  'other',
]);
