export const bankidMessagesEn = {
  'sign-with-bankid': 'Sign with BankID',
  'try-again': 'Try again',
  cancel: 'Cancel',
  'open-bankid-app': 'Open BankID',

  'sign-success': 'Signing successful!',

  // Pending
  'hintcode-qr-outstandingTransaction':
    'Start the BankID app and scan the QR code.', // RFA1
  'hintcode-mobile-outstandingTransaction':
    'Please click the button below to start your BankID app.',

  'hintcode-noClient': 'Start the BankID app and scan the QR code.', // RFA1

  'hintcode-qr-started': 'Start the BankID app and scan the QR code.', // RFA15 (tailored for qr)
  'hintcode-mobile-started':
    'Please click the button below to start your BankID app.',

  'hintcode-userSign':
    'Enter your security code in the BankID app and select Identify or Sign.', // RFA9

  'hintcode-unknown': 'Identification or signing in progress.', // RFA21

  // Failed

  'hintcode-expiredTransaction':
    'The BankID app is not responding. Please check that it’s started and that you have internet access. If you don’t have a valid BankID you can get one from your bank. Try again.', //RFA8

  'hintcode-certificateErr':
    'The BankID you are trying to use is revoked or too old. Please use a different BankID or get a new one from your internet bank.',

  'hintcode-userCancel': 'Action cancelled.', // RFA6

  'hintcode-cancelled': 'Action cancelled. Please try again.', // RFA3

  'hintcode-qr-startFailed':
    "Failed to scan the QR code. Start the BankID app and scan the QR code. Check that the BankID app is up to date. If you don't have the BankID app, you need to install it and get a BankID from your bank. Install the app from your app store or https://install.bankid.com", // RFA17
  'hintcode-mobile-startFailed':
    "The BankID app couldn't be found on your computer or mobile device. Please install it and get a BankID from your internet bank. Install the app from your app store or https://install.bankid.com.",

  'hintcode-failed-unknown': 'Something went wrong. Please try again.', // RFA21

  // Error

  'errorcode-alreadyInProgress':
    'An identification or signing for this personal number is already started. Please try again.', // RFA4

  'errorcode-invalidParameters': 'Something went wrong.',

  'errorcode-unknown': 'Unknown error. Please try again.', // RFA22

  'errorcode-unauthorized': 'Something went wrong.',

  'errorcode-notFound': 'Something went wrong.',

  'errorcode-methodNotAllowed': 'Something went wrong.',

  'errorcode-requestTimeout': 'Internal error. Please try again.', // RFA5

  'errorcode-unsupportedMediaType': 'Something went wrong.',

  'errorcode-internalError': 'Internal error. Please try again.', // RFA5

  'errorcode-maintenance': 'Internal error. Please try again.', // RFA5

  'errorcode-careosUnknown': 'Something went wrong.',

  // Common
  'hintcode-qr-unknown': '',
  'hintcode-qr-userMrtd': '',
  'hintcode-qr-userCallConfirm': '',
  'hintcode-qr-userDeclinedCall': '',
  'hintcode-qr-notSupportedByUserApp': '',
};
