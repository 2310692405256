import { z } from 'zod';
import {
  InternalLocationSchema,
  IsoDateTimeSchema,
  ReferenceSchema,
} from '../fhir-entities';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

export const RackTypeSchema = z.enum([
  'Single',
  'A',
  'B',
  'Deviations',
  'Stationary',
]);
export type RackType = z.infer<typeof RackTypeSchema>;
export const RackType = RackTypeSchema.Enum;
export const RackRegisteredInLabEventSchema = BaseEventSchema.extend({
  eventType: z.literal('RackRegisteredInLabEvent'),
  eventData: z.object({
    /**
     * Unique identifier of the rack.
     */
    rackId: z.string(),
    /**
     * Label created for the registered rack.
     */
    rackLabel: z.string(),
    /**
     * Type of rack. Racks can have Single, A, B or Deviations type.
     */
    rackType: z.string(),
    /**
     * Date and time when the rack was registered in the lab.
     */
    registeredAt: IsoDateTimeSchema,
    /**
     * Practitioner who registered the rack in the lab.
     */
    practitioner: ReferenceSchema(z.literal('Practitioner')),
    /**
     * Location of rack. Expected to be present if the event is created by Move.
     */
    location: InternalLocationSchema.optional(),
    /**
     * Reason why the rack was registered in a certain location. Expected to be present if the event is created by Move.
     */
    locationPurpose: z.string().optional(),
  }),
});

/**
 * A practitioner registers a rack in the lab.
 */
export class RackRegisteredInLabEvent extends createSchemaClass(
  RackRegisteredInLabEventSchema,
) {}
