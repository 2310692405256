import { useMutation } from '@tanstack/react-query';

import { api } from '@/lib/api';
import { type MutationConfig } from '@/lib/react-query';

export const useCreateRandomizationListUploadUrl = ({
  mutationConfig,
}: {
  mutationConfig?: MutationConfig<typeof api.samplingSession.createRandomizationListUploadUrl>;
} = {}) => {
  return useMutation({
    ...mutationConfig,
    mutationFn: api.samplingSession.createRandomizationListUploadUrl,
  });
};
