import { type ErrorComponentProps } from '@tanstack/react-router';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import { useEffect } from 'react';
import { captureException } from '@sentry/react';

export type ErrorComponentSentryProps = Pick<ErrorComponentProps, 'error'> & {
  children: React.ReactNode;
};

export const ErrorComponentSentry = ({
  error,
  children,
}: ErrorComponentSentryProps) => {
  const queryErrorResetBoundary = useQueryErrorResetBoundary();

  useEffect(() => {
    captureException(error);
    queryErrorResetBoundary.reset();
  }, [queryErrorResetBoundary, error]);

  return <>{children}</>;
};
