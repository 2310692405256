import { z } from 'zod';
import { TTLSecSchema } from '@abc-labs-ab/ts-events';
import { type ReasonForTestingOrOther } from '@careos/toxicology-types';

const RequisitionTTLSchema = z.object({
  TTLSec: TTLSecSchema,
});

const ResultTTLSchema = z.object({
  posTTLSec: TTLSecSchema,
  negTTLSec: TTLSecSchema,
});

export const RequisitionPolicySchema = z.object({
  random_selection: RequisitionTTLSchema,
  new_employee: RequisitionTTLSchema,
  incident_accident: RequisitionTTLSchema,
  health_check: RequisitionTTLSchema,
  rehabilitation: RequisitionTTLSchema,
  other: RequisitionTTLSchema,
} satisfies { [K in ReasonForTestingOrOther]: typeof RequisitionTTLSchema });

export const ResultPolicySchema = z.object({
  random_selection: ResultTTLSchema,
  new_employee: ResultTTLSchema,
  incident_accident: ResultTTLSchema,
  health_check: ResultTTLSchema,
  rehabilitation: ResultTTLSchema,
  other: ResultTTLSchema,
} satisfies { [K in ReasonForTestingOrOther]: typeof ResultTTLSchema });

export type RequisitionAccessControlPolicyDto = z.infer<
  typeof RequisitionPolicySchema
>;

export type ResultAccessControlPolicyDto = z.infer<typeof ResultPolicySchema>;

export type DefaultPolicy = {
  requisition: RequisitionAccessControlPolicyDto;
  result: ResultAccessControlPolicyDto;
};

const FIVE_YEARS_IN_SECONDS = 157_680_000;
// const ONE_YEAR_IN_SECONDS = 31_536_000;
// const ONE_MONTH_IN_SECONDS = 2_592_000;

export const workplaceDefaultPolicy: DefaultPolicy = {
  requisition: {
    random_selection: {
      TTLSec: FIVE_YEARS_IN_SECONDS,
    },
    new_employee: {
      TTLSec: FIVE_YEARS_IN_SECONDS,
    },
    incident_accident: {
      TTLSec: FIVE_YEARS_IN_SECONDS,
    },
    health_check: {
      TTLSec: FIVE_YEARS_IN_SECONDS,
    },
    rehabilitation: {
      TTLSec: FIVE_YEARS_IN_SECONDS,
    },
    other: {
      TTLSec: FIVE_YEARS_IN_SECONDS,
    },
  },
  result: {
    random_selection: {
      posTTLSec: FIVE_YEARS_IN_SECONDS,
      negTTLSec: FIVE_YEARS_IN_SECONDS,
    },
    new_employee: {
      posTTLSec: FIVE_YEARS_IN_SECONDS,
      negTTLSec: FIVE_YEARS_IN_SECONDS,
    },
    incident_accident: {
      posTTLSec: FIVE_YEARS_IN_SECONDS,
      negTTLSec: FIVE_YEARS_IN_SECONDS,
    },
    health_check: {
      posTTLSec: FIVE_YEARS_IN_SECONDS,
      negTTLSec: FIVE_YEARS_IN_SECONDS,
    },
    rehabilitation: {
      posTTLSec: FIVE_YEARS_IN_SECONDS,
      negTTLSec: FIVE_YEARS_IN_SECONDS,
    },
    other: {
      posTTLSec: FIVE_YEARS_IN_SECONDS,
      negTTLSec: FIVE_YEARS_IN_SECONDS,
    },
  },
};

export const treatmentCenterDefaultPolicy: DefaultPolicy = {
  requisition: {
    random_selection: {
      TTLSec: FIVE_YEARS_IN_SECONDS,
    },
    new_employee: {
      TTLSec: FIVE_YEARS_IN_SECONDS,
    },
    incident_accident: {
      TTLSec: FIVE_YEARS_IN_SECONDS,
    },
    health_check: {
      TTLSec: FIVE_YEARS_IN_SECONDS,
    },
    rehabilitation: {
      TTLSec: FIVE_YEARS_IN_SECONDS,
    },
    other: {
      TTLSec: FIVE_YEARS_IN_SECONDS,
    },
  },
  result: {
    random_selection: {
      posTTLSec: FIVE_YEARS_IN_SECONDS,
      negTTLSec: FIVE_YEARS_IN_SECONDS,
    },
    new_employee: {
      posTTLSec: FIVE_YEARS_IN_SECONDS,
      negTTLSec: FIVE_YEARS_IN_SECONDS,
    },
    incident_accident: {
      posTTLSec: FIVE_YEARS_IN_SECONDS,
      negTTLSec: FIVE_YEARS_IN_SECONDS,
    },
    health_check: {
      posTTLSec: FIVE_YEARS_IN_SECONDS,
      negTTLSec: FIVE_YEARS_IN_SECONDS,
    },
    rehabilitation: {
      posTTLSec: FIVE_YEARS_IN_SECONDS,
      negTTLSec: FIVE_YEARS_IN_SECONDS,
    },
    other: {
      posTTLSec: FIVE_YEARS_IN_SECONDS,
      negTTLSec: FIVE_YEARS_IN_SECONDS,
    },
  },
};
