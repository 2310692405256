import type { FlowType } from '@careos/react-ui/BankId';
import { enforceExhaustive } from '@careos/react-ui/utils';
import type {
  DonorBankIdSignRequestDto,
  DonorCheckBankidSigningRequestDto,
} from '@careos/toxicology-types';
import {
  isErrorDto,
  type BankIdCheckResponseDto,
  type BankIdRequestErrorResponseDto,
  type BankIdSignResponseDto,
  type CleanCompletionResponseDto,
} from '@careos/types';
import { useRef } from 'react';

import { api } from '@/lib/api';

export type PollFuncs = {
  onError: (error: BankIdRequestErrorResponseDto | Error) => void;
  onUpdate: (args: BankIdCheckResponseDto) => void;
  onCompletion: (args: CleanCompletionResponseDto) => void;
};

export type DonorBankIdInitiate = {
  flowType: FlowType;
  signArgs: DonorBankIdSignRequestDto;
  onInitDone?: (args: BankIdSignResponseDto) => void;
} & PollFuncs;

export type BankIdPoll = DonorCheckBankidSigningRequestDto & PollFuncs;

export const useBankIdPollingDonor = () => {
  const getResultTimeout = useRef<NodeJS.Timeout>();
  const orderRef = useRef<string>();
  const inProgress = useRef<boolean>(false);
  const pinCode = useRef<string>();
  const transactionId = useRef<string>();

  const abort = () => {
    clearTimeout(getResultTimeout.current);

    if (!orderRef.current || !pinCode.current || !transactionId.current) return;

    if (inProgress.current) {
      api.bankId.donorCancel({
        orderRef: orderRef.current,
        pinCode: pinCode.current,
        transactionId: transactionId.current,
      });
    }
  };

  const pollForResult = ({
    onUpdate,
    onError,
    onCompletion,
    orderRef,
    pinCode,
    transactionId,
  }: BankIdPoll) => {
    const getResult = () => {
      api.bankId
        .donorCheck({ orderRef, pinCode, transactionId })
        .then((res) => {
          if (isErrorDto(res)) {
            throw res;
          }

          if (orderRef && res.orderRef !== orderRef) {
            inProgress.current = false;
            abort();
            return;
          }

          onUpdate(res);

          const { status } = res;
          switch (status) {
            case 'pending': {
              getResultTimeout.current = setTimeout(getResult, 1000);
              break;
            }
            case 'complete': {
              inProgress.current = false;
              onCompletion(res);
              break;
            }
            case 'failed': {
              inProgress.current = false;
              break;
            }
            default:
              enforceExhaustive(status);
          }
        })
        .catch(onError);
    };

    getResult();
  };

  const init = ({ onInitDone, onUpdate, onCompletion, signArgs, onError }: DonorBankIdInitiate) => {
    api.bankId
      .donorSign(signArgs)
      .then((res) => {
        if (isErrorDto(res)) {
          throw res;
        }

        if (onInitDone) {
          onInitDone(res);
        }

        pinCode.current = signArgs.pinCode;
        transactionId.current = signArgs.transactionId;
        orderRef.current = res.orderRef;
        inProgress.current = true;

        pollForResult({
          orderRef: res.orderRef,
          onUpdate,
          onCompletion,
          onError,
          pinCode: signArgs.pinCode,
          transactionId: signArgs.transactionId,
        });
      })
      .catch(onError);
  };

  return {
    init,
    pollForResult,
    abort,
  };
};
