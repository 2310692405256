export enum IdentifierSystem {
  /**
   * Personal Identity Number for a person registered in Sweden by The Swedish Tax Agency (Skatteverket).
   */
  PersonalIdentityNumber = 'urn:oid:1.2.752.129.2.1.3.1',
  /**
   * Reference type to a patient.
   */
  PatientReference = 'uri:careos.io/patient-references',
  /**
   * Id for objects in the HSA-catalogue.
   */
  HsaId = 'urn:oid:1.2.752.129.2.1.4.1',
  /**
   * NPU codes defines measurable properties in laboratorie medicine.
   * This subset of codes in addition includes the nationally defined properties for use in Sweden.
   */
  NpuCode = 'urn:oid:1.2.752.108.1',
  /**
   * Identification of organizations integrated with the CareOS system e.g. ABC_LABS.
   */
  Organization = 'uri:careos.io/organization',
  /**
   * Identification of a policy in CareOS.
   */
  Policy = 'uri:careos.io/policy',
  /**
   * Identification of systems integrated with the CareOS system e.g. CGM_ANALYTIX
   */
  System = 'uri:careos.io/system',
  /**
   * Identification of an activity definition within the CareOS system.
   * Definitions are usually static configuration fed into CareOS
   */
  ActivityDefinition = 'uri:careos.io/definitions/activities',
  /**
   * Identification of an observation definition within the CareOS system.
   * Definitions are usually static configuration fed into CareOS.
   */
  ObservationDefinition = 'uri:careos.io/definitions/observations',
  /**
   * Identification of a plan definition within the CareOS system.
   * Definitions are usually static configuration fed into CareOS.
   */
  PlanDefinition = 'uri:careos.io/definitions/plans',
  /**
   * Identification of a specimen definition within the CareOS system.
   * Definitions are usually static configuration fed into CareOS.
   */
  SpecimenDefinition = 'uri:careos.io/definitions/specimen',
  /**
   * Identification of Synlab devices, e.g. ink printer.
   */
  SynlabDevice = 'uri:careos.io/devices/synlab',
  /**
   * Identification for requisitions sent from Werlabs
   */
  WerlabsRequisition = 'uri:careos.io/requisitions/werlabs',
  /**
   * Identification for requisitions sent from Edts
   */
  EdtsRequisition = 'uri:careos.io/requisitions/edts',
  /**
   * Identification for Werlabs Paying Unit
   */
  WerlabsPayingUnit = 'uri:careos.io/paying-unit/werlabs',
  /**
   * Identification for Werlabs Requesting Unit
   */
  WerlabsRequestingUnit = 'uri:careos.io/requesting-unit/werlabs',
  /**
   * Identification for Abbott Client References when creating requisitions
   */
  AbbottClientReference = 'uri:careos.io/requisitions/client-references/abbott',
  /**
   * Identification for caregivers and labs in Stockholm
   */
  KombikaCode = 'uri:careos.io/organization/kombika',
  /**
   * Global standards for health terms
   */
  SnomedCode = 'https://browser.ihtsdotools.org',
  /**
   * Photometric analysis procedures in CareOS.
   */
  ImmunoAnalyzerProcedure = 'uri:careos.io/procedures/immuno-analyzer',
  /**
   * Mass spectrometry procedures in CareOS.
   */
  MassSpectrometryProcedure = 'uri:careos.io/procedures/mass-spectrometry',
  /**
   * PCR procedures in CareOS.
   */
  PCRProcedure = 'uri:careos.io/procedures/PCR',
  /**
   * Assay system from the fast finder LIMS
   */
  FastFinderAssay = 'uri:careos.io/assays/fast-finder',
  /**
   * Test code system from CGM Analytix LIMS
   */
  CgmAnalytixTestCode = 'uri:careos.io/cgm-analytix/test-codes',
  /**
   * Result report system from the CGM Analytix LIMS
   */
  CgmAnalytixResultReport = 'uri:careos.io/cgm-analytix/reports/results',
  /**
   * Operator ID system from the CGM Analytix LIMS
   */
  CgmAnalytixOperatorId = 'uri:careos.io/cgm-analytix/operator-ids',
  /**
   * Identification for keycloak users.
   */
  KeyCloakUserId = 'uri:careos.io/keycloak',
  /**
   * Auth0 user identifiers
   */
  Auth0User = 'uri:careos.io/auth0/users',
  /**
   * Careos System ABC Gates
   */
  AbcGates = 'uri:careos.io/abc-gates',
  /**
   * Careos System ABC MRO
   */
  AbcMro = 'uri:careos.io/abc-mro',
  /**
   * Careos System ABC Workplace Solutions
   */
  AbcWorkplaceSolutions = 'uri:careos.io/abc-workplace-solutions',
  /**
   * System for CareOS requisitions
   */
  Requisition = 'uri:careos.io/requisitions',
  /**
   * System for accrediations
   */
  Accreditations = 'uri:careos.io/accreditations',
  /**
   * System for sampling kits
   */
  SamplingKits = 'uri:careos.io/sampling-kits',
  /**
   * System for sampling sessions
   */
  SamplingSessions = 'uri:careos.io/sampling-sessions',
  /**
   * System for TargetLynx test result report
   */
  TargetLynxTestResultReport = 'uri:careos.io/target-lynx/test-report',
  /**
   * System for TargetLynx test type
   */
  TargetLynxTestType = 'uri:careos.io/target-lynx/test-type',
  /**
   * System for TargetLynx run type (e.g. screening, verification)
   */
  TargetLynxTestRunType = 'uri:careos.io/target-lynx/test-run-type',
  /**
   * System for TargetLynx internal standard compound
   */
  TargetLynxInternalStandardCompound = 'uri:careos.io/target-lynx/internal-standard-compound',
  /**
   * System for TargetLynx test compound
   */
  TargetLynxTestCompound = 'uri:careos.io/target-lynx/test-compound',
  /**
   * System for TargetLynx test data attribute
   */
  TargetLynxTestDataAttribute = 'uri:careos.io/target-lynx/test-attribute',
  /**
   * System for TargetLynx operators signatures
   */
  TargetLynxOperatorSignature = 'uri:careos.io/target-lynx/operator',
  /*
   * System for specimens registered in Gates
   */
  Specimen = 'uri:careos.io/specimen',
  /**
   * DEPRECATED: Global standards for health terms
   */
  DeprecatedSnomedCode = 'http://snomed.info/id/',
  /**
   * System for rack locations
   */
  RackLocation = 'uri:careos.io/rack/location',
}
