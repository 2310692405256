import { cn } from '#utils';
import { Link, type LinkOptions } from '@tanstack/react-router';

type ExtendedLinkOptions = (LinkOptions & { label: string; to: string })[];

export type NavigationItemsProps = {
  linkOptions: ExtendedLinkOptions;
  className?: string;
};

export const NavigationItems = ({
  linkOptions,
  className,
}: NavigationItemsProps) => {
  return (
    <div className={cn('flex sm:gap-x-4', className)}>
      {linkOptions.map((option) => (
        <Link
          {...option}
          key={option.to}
          activeProps={{ className: `text-primary` }}
          activeOptions={{ exact: true }}
          inactiveProps={{ className: `text-gray-900` }}
          className="px-4 py-2 hover:bg-indigo-50/75 rounded-md transition-colors whitespace-nowrap"
        >
          {option.label}
        </Link>
      ))}
    </div>
  );
};
