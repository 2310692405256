import { nonEmptyString } from '@careos/types';
import { z } from 'zod';

export const RandomizationListFileResponseDtoSchema = z.object({
  content: z.string().base64().min(1),
  fileName: nonEmptyString,
});

export type RandomizationListFileResponseDto = z.infer<
  typeof RandomizationListFileResponseDtoSchema
>;
