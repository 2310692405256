export const genericEn = {
  retry: 'Retry',

  language: {
    language: 'Language',
    english: 'English',
    swedish: 'Swedish',
  },

  table: {
    no_results: 'No results.',
    pagination: {
      rows_per_page: 'Rows per page:',
      item_count: '{{startRow}}-{{endRow}} of {{totalRows}}',
    },
  },

  navigation: {
    goToStartPage: 'Go to start page',
    leave_warning: 'Are you sure you want to leave? All changes will be lost.',
  },

  user_menu: {
    logout: 'Log out',
  },

  error: {
    title: 'Error message:',
    message: 'An error occurred',
    action: 'Please try again, or contact support if the problem persists.',
  },

  form: {
    firstName: {
      label: 'First name',
    },
    lastName: {
      label: 'Last name',
    },
    email: {
      label: 'Email',
    },
    identificationSwitch: {
      description: 'The person has a Swedish personal number',
    },
    swedishPersonalNumber: {
      label: 'Swedish personal number',
      placeholder: 'YYYYMMDD-XXXX',
    },
    personalID: {
      label: 'Personal ID',
    },
    employer: {
      label: 'Employer',
      placeholder: 'Choose employer',
    },

    optional: 'optional',
    back: 'Back',
    next: 'Next',
  },
  donorInteractionType: {
    label: 'Sampling form',
    placeholder: 'Choose sampling form',
    digital: 'Digital',
    options: {
      at_workplace: 'In person by ABC at your site',
      at_abc_labs: "At ABC's facilities in Stockholm",
      video_at_workplace: 'Digitally by ABC at your site',
    },
  },
};
