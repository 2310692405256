import { z } from 'zod';
import { IsoDateTimeSchema, ReferenceSchema } from '../fhir-entities';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

export const SpecimenMovedInLabEventSchema = BaseEventSchema.extend({
  eventType: z.literal('SpecimenMovedInLabEvent'),
  eventData: z.object({
    /**
     * Unique identifier for the specimen.
     */
    specimenId: z.string().uuid(),
    /**
     * The rack specimen moved into.
     */
    rackId: z.string().uuid(),
    /**
     * When the specimen was moved.
     */
    movedAt: IsoDateTimeSchema,
    /**
     * {@link Practitioner} who moved the specimen.
     */
    practitioner: ReferenceSchema(z.literal('Practitioner')),
  }),
});

/**
 * Specimen was moved into a rack in the lab.
 */
export class SpecimenMovedInLabEvent extends createSchemaClass(
  SpecimenMovedInLabEventSchema,
) {}
