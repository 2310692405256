import { Badge } from '@careos/react-ui/Badge';
import {
  OrderReason,
  type GetSamplingSessionsResponseDto,
  type SamplingSessionStatus,
} from '@careos/toxicology-types';
import { createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import { t } from 'i18next';

import i18n from '@/i18n';

import { ActionMenu, type ActionMenuCallbackProps } from './action-menu';
import { DonorName } from './donor-name';

const columnHelper =
  createColumnHelper<GetSamplingSessionsResponseDto['samplingSessions'][number]>();

const getStatusBadgeColor = (status: SamplingSessionStatus) => {
  switch (status) {
    case 'requested':
      return 'text-[#4031c6] bg-[#a89ff5]';
    case 'started':
      return 'text-[#7E6824] bg-[#FFF0C3]';
    case 'finished':
    case 'reported':
      return 'text-[#7b7b7b] bg-[#CDCDCD]';
    default:
      return 'bg-red-500';
  }
};

export const getSessionColumns = (actionMenuCallbackProps: ActionMenuCallbackProps) => [
  columnHelper.display({
    id: 'actions',
    cell: ({ row }) => {
      const session = row.original;
      const sessionId = row.getValue<string>('sessionId');
      const reasonForTesting = row.getValue<OrderReason>('reasonForTesting');
      const status = row.getValue<SamplingSessionStatus>('status');

      return (
        <ActionMenu
          sessionId={sessionId}
          reasonForTesting={reasonForTesting}
          status={status}
          hasGeneratedNumberSeries={Boolean(session.generatedNumberSeries)}
          randomizationListExists={session.randomizationListExists}
          {...actionMenuCallbackProps}
        />
      );
    },
  }),
  columnHelper.accessor('status', {
    header: t('table.status.header'),
    cell: ({ getValue }) => {
      const color = getStatusBadgeColor(getValue());
      return (
        <Badge className={`font-bold ${color}`} variant={'static'}>
          {i18n.t(`table.status.session.${getValue()}`).toUpperCase()}
        </Badge>
      );
    },
  }),
  columnHelper.accessor('sessionId', {
    header: t('table.session_id.header'),
    cell: (prop) => prop.getValue().slice(0, 6),
  }),
  columnHelper.accessor('samplingDate', {
    header: t('table.should_sample_at.header'),
    cell: ({ row }) => {
      const samplingDate = row.getValue<string>('samplingDate');
      const formattedSamplingDate = format(new Date(samplingDate), 'yyyy-MM-dd');
      const timeSlot = row.original.timeSlot;

      return `${formattedSamplingDate} ${timeSlot}`;
    },
  }),
  columnHelper.accessor('donor', {
    header: 'Donator',
    cell: (prop) => {
      const donor = prop.getValue();
      return donor ? <DonorName donor={donor} /> : <span>-</span>;
    },
  }),
  columnHelper.accessor('employer.name', {
    header: t('table.employer.header'),
  }),
  columnHelper.accessor('samplingDetails', {
    header: t('table.test_type.header'),
    cell: (prop) =>
      prop
        .getValue()
        .map((detail) => detail.testType)
        .join(', '),
  }),
  columnHelper.accessor('sampleCount', {
    header: t('table.sample_count.header'),
  }),

  columnHelper.accessor('reasonForTesting', {
    header: t('table.reason.header'),
    cell: (prop) => {
      const reasonValue = prop.getValue();
      const translation = t(`table.reason.${reasonValue}`);
      return translation;
    },
  }),

  columnHelper.accessor('donorInteractionType', {
    header: t('generic.donorInteractionType.label'),
    cell: (prop) => {
      const donorInteractionType = prop.getValue();
      if (!donorInteractionType) {
        return '-';
      } else {
        return t(`generic.donorInteractionType.options.${donorInteractionType}`);
      }
    },
  }),
];
