import { isInvalidGrantError, isLoginRequiredError } from '#utils/auth';
import type { Auth0ContextInterface } from '@auth0/auth0-react';

import { ofetch, type $Fetch, type FetchOptions } from 'ofetch';

export type CreateHttpClientParams = Pick<
  Auth0ContextInterface,
  'loginWithRedirect' | 'getAccessTokenSilently'
> & {
  baseURL: FetchOptions['baseURL'];
};

let httpClient: $Fetch;
let tokenlessHttpClient: $Fetch;

const createHttpClient = ({
  loginWithRedirect,
  getAccessTokenSilently,
  baseURL,
}: CreateHttpClientParams) => {
  const baseOptions = {
    baseURL,
    retry: 0,
  };

  tokenlessHttpClient = ofetch.create({
    ...baseOptions,
  });

  httpClient = ofetch.create({
    ...baseOptions,
    async onRequest({ options }) {
      try {
        const accessToken = await getAccessTokenSilently();
        options.headers.append('Authorization', `Bearer ${accessToken}`);
      } catch (error) {
        if (isLoginRequiredError(error) || isInvalidGrantError(error)) {
          loginWithRedirect({
            appState: { returnTo: window.location.origin },
          });
          return;
        }
        throw error;
      }
    },
  });
};

export { httpClient, createHttpClient, tokenlessHttpClient };
