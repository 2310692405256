import {
  PersonalIDField,
  PersonalIDFieldSchema,
  SwedishPersonalNumberField,
  SwedishPersonalNumberFieldSchema,
} from '@careos/react-ui/CareosForm';
import { Checkbox } from '@careos/react-ui/Checkbox';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@careos/react-ui/Form';
import { Input } from '@careos/react-ui/Input';
import { isValidPhoneNumber, PhoneInput } from '@careos/react-ui/PhoneInput';
import { Switch } from '@careos/react-ui/Switch';
import { nonEmptyString } from '@careos/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { BackButton } from './back-button';
import { NextButton } from './next-button';

const IdentificationSchema = z.discriminatedUnion('hasSwedishPersonalNumber', [
  z
    .object({
      hasSwedishPersonalNumber: z.literal(true),
    })
    .merge(SwedishPersonalNumberFieldSchema),
  z
    .object({
      hasSwedishPersonalNumber: z.literal(false),
    })
    .merge(PersonalIDFieldSchema),
]);

const FormSchema = z.object({
  identification: IdentificationSchema,
  firstName: z.string().min(2),
  lastName: z.string().min(2),
  phoneNumber: z.string().refine(isValidPhoneNumber, { message: 'Invalid phone number' }),
  attester: z.discriminatedUnion('needsAttester', [
    z
      .object({
        needsAttester: z.literal(true),
        firstName: nonEmptyString,
        lastName: nonEmptyString,
      })
      .merge(PersonalIDFieldSchema),

    z.object({ needsAttester: z.literal(false) }),
  ]),
  collectorConfirm: z.boolean().refine((isNotConfirmed) => isNotConfirmed, {
    message: 'You need to confirm in order to proceed',
  }),
});

export type RequisitionFormDonorInfo = z.infer<typeof FormSchema>;

type Props = {
  onUpdate: (formVals: RequisitionFormDonorInfo, stepDirection: 'previous' | 'next') => void;
  defaultValues: RequisitionFormDonorInfo;
};

export const RequisitionFormDonorInfo = ({ onUpdate, defaultValues }: Props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'requisition_form.donor_info',
  });
  const { t: tGeneric } = useTranslation('translation', {
    keyPrefix: 'generic',
  });

  const form = useForm<RequisitionFormDonorInfo>({
    resolver: zodResolver(FormSchema),
    defaultValues,
  });

  function onSubmit(values: RequisitionFormDonorInfo) {
    onUpdate(values, 'next');
  }

  function onGoBack() {
    onUpdate(form.getValues(), 'previous');
  }

  const watchHasSwedishPersonalNumber = form.watch('identification.hasSwedishPersonalNumber');
  const watchNeedsAttester = form.watch('attester.needsAttester');

  return (
    <div className="min-w-[350px]">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col space-y-4">
          <FormField
            control={form.control}
            name="identification.hasSwedishPersonalNumber"
            render={({ field }) => (
              <FormItem className="flex flex-row items-baseline justify-between rounded-lg">
                <FormDescription>
                  {tGeneric('form.identificationSwitch.description')}
                </FormDescription>

                <FormControl>
                  <Switch checked={field.value} onCheckedChange={field.onChange} />
                </FormControl>
              </FormItem>
            )}
          />
          {watchHasSwedishPersonalNumber ? (
            <SwedishPersonalNumberField
              control={form.control}
              name="identification.swedishPersonalNumber"
            />
          ) : (
            <PersonalIDField control={form.control} name="identification.personalID" />
          )}
          <FormField
            control={form.control}
            name="firstName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('first_name.label')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>

                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="lastName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('last_name.label')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>

                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="phoneNumber"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('phone_number.label')}</FormLabel>
                <FormControl>
                  <PhoneInput {...field} defaultCountry="SE" />
                </FormControl>

                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="attester.needsAttester"
            render={({ field }) => (
              <FormItem className="flex flex-row items-baseline justify-between rounded-lg">
                <FormDescription>{t('attester.switch.label')}</FormDescription>

                <FormControl>
                  <Switch
                    checked={field.value}
                    onCheckedChange={(val) => {
                      field.onChange(val);
                      form.setValue('attester', {
                        needsAttester: val,
                        firstName: '',
                        lastName: '',
                        personalID: '',
                      });
                    }}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          {watchNeedsAttester && (
            <>
              <FormField
                control={form.control}
                name="attester.firstName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('attester.first_name.label')}</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="attester.lastName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('attester.last_name.label')}</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />
              <PersonalIDField
                label={t('attester.personalId.label')}
                control={form.control}
                name="attester.personalID"
              />
            </>
          )}
          <FormField
            control={form.control}
            name="collectorConfirm"
            render={({ field }) => (
              <FormItem>
                <div className="my-3 flex items-center gap-x-2 rounded-md bg-slate-50 px-4">
                  <FormControl>
                    <Checkbox
                      className="size-6 leading-none"
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormLabel className="leading-1 py-4">{t('collector_confirm.label')}</FormLabel>
                </div>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex justify-between">
            <BackButton onClick={onGoBack} />
            <NextButton className="w-1/2" />
          </div>
        </form>
      </Form>
    </div>
  );
};
