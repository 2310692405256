import { z } from 'zod';
import { AddressSchema } from './address.interface';
import { CodeableConceptSchema } from './codeable-concept.interface';
import { ContactPointSchema } from './contact-point.interface';
import { HumanNameSchema } from './human-name.interface';
import { IdentifierSchema } from './identifier.interface';

export const OrganizationSchema = z.object({
  /**
   * Unique resource ID.
   */
  organizationId: z.string().optional(),

  /**
   * The environment of the Organization, e.g. workplace or treatment center
   *
   * @example for workplace
   * organizationEnvironment: {
   *  coding: [{
   *    system: 'https://browser.ihtsdotools.org', // Snomed CT
   *    code: '285141008', // Snomed code for Workplace enivronment
   *  }],
   *  text: 'Organization environment'
   * }
   */
  type: CodeableConceptSchema.optional(),

  /**
   * Other organization identifiers.
   */
  identifiers: z.array(IdentifierSchema),

  /**
   * Name of the organization.
   */
  name: z.string().optional(),

  /**
   * Primary address.
   */
  address: AddressSchema.optional(),

  /**
   * Points of contact for different purposes.
   */
  contacts: z.array(
    z.object({
      purpose: CodeableConceptSchema.optional(),
      names: z.array(HumanNameSchema),
      telecom: z.array(ContactPointSchema),
      address: AddressSchema.optional(),
      Identifiers: z.array(IdentifierSchema).optional(),
    }),
  ),
});

/**
 * An organized group of people, including companies, institutions, healthcare groups, etc.
 */
export type Organization = z.infer<typeof OrganizationSchema>;
