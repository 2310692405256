import { toast } from '@careos/react-ui/Toast';
import type { RandomizationListFileResponseDto } from '@careos/toxicology-types';
import { t } from 'i18next';

import { useCreateRandomizationListUploadUrl } from '../api/create-randomization-list-upload-url';
import { useRandomizationListFile } from '../api/get-randomization-list-file';

export const useRandomizationList = (sessionId: string) => {
  const { refetch: getFileQuery } = useRandomizationListFile({
    sessionId,
    queryConfig: { enabled: false },
  });
  const createUrlMutation = useCreateRandomizationListUploadUrl({
    mutationConfig: {
      onSuccess: async (data) => {
        await navigator.clipboard.writeText(data.url);
        toast({
          title: t('table.actions.copy_randomization_list_url_toast_message'),
        });
      },
    },
  });

  const generateLink = () => {
    createUrlMutation.mutate(sessionId);
  };

  const downloadList = async () => {
    const { data } = await getFileQuery();
    if (!data) {
      toast({
        title: t('table.actions.downloading_randomization_list_failed_toast_message'),
        variant: 'destructive',
      });
      return;
    }

    saveFile(data);

    toast({
      title: t('table.actions.download_randomization_list_toast_message'),
    });
  };

  const saveFile = ({ content, fileName }: RandomizationListFileResponseDto) => {
    const href = content;
    const a = Object.assign(document.createElement('a'), {
      href,
      style: 'display:none',
      download: fileName,
    });
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  return {
    generateAndCopyLink: generateLink,
    downloadList,
  };
};
