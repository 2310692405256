import { z } from 'zod';

export const InitiateRemoteSigningRequestDtoSchema = z.object({
  transactionId: z.string().uuid(),
  type: z.union([z.literal('SMS'), z.literal('LINK')]),
});

export type InitiateRemoteSigningRequestDto = z.infer<
  typeof InitiateRemoteSigningRequestDtoSchema
>;
