import { nonEmptyString } from '@careos/types';
import type { FieldValues } from 'react-hook-form';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '#components/Form/Form';
import { Input } from '#components/Input/Input';
import type { FormFieldBaseProps } from '../types/FormField';

export const PersonalIDFieldSchema = z.object({
  personalID: nonEmptyString,
});

type PersonalIDFieldProps<T extends FieldValues> = Omit<
  FormFieldBaseProps<T>,
  'label'
> & { label?: string };

export const PersonalIDField = <T extends FieldValues>({
  control,
  name,
  label,
}: PersonalIDFieldProps<T>) => {
  const { t } = useTranslation();
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel>{label ?? t('generic.form.personalID.label')}</FormLabel>
          <FormControl>
            <Input {...field} value={field.value || ''} />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
