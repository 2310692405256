import { isErrorDto, type BankIdRequestErrorResponseDto } from '@careos/types';
import { useTranslation } from 'react-i18next';

export const Error = (props: BankIdRequestErrorResponseDto) => {
  const { t } = useTranslation('translation', { keyPrefix: 'bankid' });
  const error = isErrorDto(props) ? props.errorCode : 'careosUnknown';

  return (
    <div className="grid place-content-center h-full">
      <h2 className="text-3xl font-extrabold">{t(`errorcode-${error}`)}</h2>
    </div>
  );
};
