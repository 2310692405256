import { type PendingResponseDto } from '@careos/types';
import { useTranslation } from 'react-i18next';

type OpenMobileAppProps = PendingResponseDto & {
  openBankIDUrl: string;
  onCancel: () => void;
};

export const OpenMobileApp = ({
  hintCode,
  openBankIDUrl,
  onCancel,
}: OpenMobileAppProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'bankid',
  });

  const hint = t([
    `hintcode-mobile-${hintCode}`,
    `hintcode-${hintCode}`,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ] as any);

  return (
    <div className="px-4 flex flex-col gap-y-8 p-2">
      <p>{hint}</p>
      <a
        href={openBankIDUrl}
        referrerPolicy="origin"
        className="bg-black px-8 py-4 text-white"
      >
        {t('open-bankid-app')}
      </a>
      <button onClick={onCancel}>{t('cancel')}</button>
    </div>
  );
};
