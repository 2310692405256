import type {
  BankIdCheckResponseDto,
  BankIdRequestErrorResponseDto,
} from '@careos/types';

import { QRScanPending } from './QRScanPending';
import { enforceExhaustive } from '#utils';
import { useTranslation } from 'react-i18next';

import { OpenMobileApp } from './OpenMobileApp';
import { ResultFailed, type ResultFailedActions } from './ResultFailed';
import { ResultSuccess } from './ResultSuccess';
import { Error } from './Error';
import Spinner from '#components/Spinner/Spinner';
import { BankIdLayout } from './BankIdLayout';

type FlowTypeSpecificProps =
  | { flowType: 'qr' }
  | { flowType: 'mobile'; openBankIDUrl?: string };
export type FlowType = FlowTypeSpecificProps['flowType'];
export type BankIdError = BankIdRequestErrorResponseDto & { status: 'error' };
export type BankIdResponse = BankIdCheckResponseDto | BankIdError;
export type BankIdProps = {
  bankIdResponse: BankIdResponse | undefined;
} & ResultFailedActions &
  FlowTypeSpecificProps;

export const BankId = ({ bankIdResponse, ...rest }: BankIdProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'bankid' });

  const isInitiatingSigning = !bankIdResponse;
  if (isInitiatingSigning) {
    return (
      <BankIdLayout status="pending" className="justify-center">
        <Spinner size="lg" />
      </BankIdLayout>
    );
  }

  const { status } = bankIdResponse;
  const BankIdStatus = () => {
    switch (status) {
      case 'pending':
        if (rest.flowType === 'qr') {
          return <QRScanPending {...bankIdResponse} />;
        }
        if (!rest.openBankIDUrl) return;
        return (
          <OpenMobileApp
            {...bankIdResponse}
            onCancel={rest.onCancel}
            openBankIDUrl={rest.openBankIDUrl}
          />
        );

      case 'complete':
        return <ResultSuccess />;
      case 'failed':
        return <ResultFailed {...bankIdResponse} {...rest} />;
      case 'error':
        return <Error errorCode={bankIdResponse.errorCode} />;
      default:
        enforceExhaustive(status);
    }
  };

  const heading =
    status === 'complete' ? t('sign-success') : t('sign-with-bankid');

  return (
    <BankIdLayout status={status}>
      {status !== 'error' && (
        <h2 className="text-3xl mb-8 font-extrabold">{heading}</h2>
      )}
      <BankIdStatus />
    </BankIdLayout>
  );
};
