import { BankId, type BankIdResponse } from '@careos/react-ui/BankId';
import { useNavigate } from '@tanstack/react-router';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useBankIdPollingDonor, type DonorBankIdInitiate } from '../hooks/use-bankid-polling-donor';

type SameDeviceProps = {
  pinCode: string;
  transactionId: string;
  onCancel: () => void;
} & Pick<DonorBankIdInitiate, 'onCompletion'>;

export const BankIdSameDevice = ({
  pinCode,
  transactionId,
  onCancel,
  onCompletion,
}: SameDeviceProps) => {
  const { init, abort } = useBankIdPollingDonor();
  const [bankIdData, setBankIdData] = useState<BankIdResponse>();
  const hasBeenInitiated = useRef(false);
  const [openBankIDUrl, setOpenBankIDUrl] = useState<string>();
  const navigate = useNavigate();

  const handleRetryPolling = () => {
    hasBeenInitiated.current = false;
    startPolling();
  };

  const startPolling = useCallback(() => {
    if (hasBeenInitiated.current) return;
    init({
      flowType: 'mobile',
      signArgs: {
        pinCode,
        transactionId,
      },
      onError: (error) => {
        //INFO: This handles the case when the transaction is not valid
        if (error instanceof Error) {
          navigate({
            from: '/donor/$transactionId/sign-consent/bank-id',
            to: '/donor/$transactionId/pin-code',
          });
          return;
        }
        setBankIdData({
          status: 'error',
          ...error,
        });
      },
      onCompletion,
      onInitDone: ({ autoStartToken }) => {
        setOpenBankIDUrl(`https://app.bankid.com/?autostarttoken=${autoStartToken}&redirect=null`);
      },
      onUpdate: setBankIdData,
    });
    hasBeenInitiated.current = true;
  }, [init, pinCode, transactionId, navigate, onCompletion]);

  useEffect(() => {
    startPolling();
    return () => {
      abort();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="flex p-2.5">
      <BankId
        onCancel={onCancel}
        onTryAgain={handleRetryPolling}
        bankIdResponse={bankIdData}
        flowType="mobile"
        openBankIDUrl={openBankIDUrl}
      />
    </div>
  );
};
