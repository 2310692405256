import { createSchemaClass } from '@abc-labs-ab/ts-events';
import { z } from 'zod';

export const GenerateRandomNumberSeriesRequestDtoSchema = z
  .object({
    maxNumber: z.number().int().positive(),
    amountToGenerate: z.number().int().positive(),
  })
  .refine((data) => data.maxNumber >= data.amountToGenerate, {
    message:
      'The maximum number must be greater than or equal to the amount to generate',
    path: ['amountToGenerate'],
  });

export class GenerateRandomNumberSeriesRequestDto extends createSchemaClass(
  GenerateRandomNumberSeriesRequestDtoSchema,
) {}
