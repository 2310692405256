import { z } from 'zod';
import { BaseEventSchema } from './base-event';
import { createSchemaClass } from '../utils';
import {
  IdentifierSchema,
  IsoDateTimeSchema,
  ReferenceSchema,
} from '../fhir-entities';

export const RandomizationListUploadLinkCreatedEventSchema =
  BaseEventSchema.extend({
    eventType: z.literal('RandomizationListUploadLinkCreatedEvent'),
    eventData: z.object({
      /**
       * Reference to the session
       */
      session: IdentifierSchema,

      /**
       * When the link was created.
       */
      createdAt: IsoDateTimeSchema,

      /**
       * {@link Practitioner} who requested the upload link.
       */
      practitioner: ReferenceSchema(z.literal('Practitioner')),
    }),
  });

/**
 * An upload link was created for a sampling session
 */
export class RandomizationListUploadLinkCreatedEvent extends createSchemaClass(
  RandomizationListUploadLinkCreatedEventSchema,
) {}
