import { Button } from '@careos/react-ui/Button';
import { PdfRender } from '@careos/react-ui/PdfRender';
import { SentryErrorBoundary } from '@careos/react-ui/utils';
import { createFileRoute, Link, linkOptions } from '@tanstack/react-router';
import { SignatureIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { ErrorAlert } from '@/components/error-alert';

export const Route = createFileRoute('/donor/$transactionId/sign-consent/')({
  component: RouteComponent,
});

function RouteComponent() {
  const context = Route.useRouteContext();

  const { t } = useTranslation('translation', {
    keyPrefix: 'requisition_form.signing',
  });

  const signingOptionLinks = [
    linkOptions({
      from: Route.fullPath,
      to: '/donor/$transactionId/sign-consent/handwritten',
      label: t('sign_by_hand'),
      Icon: SignatureIcon,
    }),
  ];

  return (
    <div className="mx-auto flex max-w-[800px] flex-col gap-1.5 p-1.5">
      <PdfRenderWithFallback pdf={context.remoteSigning.pdf} />
      {signingOptionLinks.map(({ Icon, label, ...link }) => (
        <Button asChild size="lg" className="rounded-sm" key={link.to}>
          <Link {...link}>
            <Icon /> {label}
          </Link>
        </Button>
      ))}
    </div>
  );
}

function PdfRenderWithFallback({ pdf }: { pdf: string }) {
  const { t } = useTranslation();
  const fallback = <ErrorAlert>{t('requisition_form.signing.donor.pdf_error')}</ErrorAlert>;
  return (
    <SentryErrorBoundary fallback={fallback}>
      <PdfRender pdfSource={pdf} />
    </SentryErrorBoundary>
  );
}
