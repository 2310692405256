import { create } from 'zustand';

import type { RequisitionFormDonorInfo } from '@/features/order/form/components/requisition-form-donor-info';
import type { SamplingForm } from '@/features/order/form/components/requisition-form-sampling';
import type { RequisitionFormTestInfo } from '@/features/order/form/components/requisition-form-test-info';

type Signing = {
  transactionId: string;
} | null;

type Created = {
  pdf: string;
  donorPassword: string;
};

type State = {
  testInfoForm: RequisitionFormTestInfo | null;
  donorInfoForm: RequisitionFormDonorInfo | null;
  samplingForm: SamplingForm;
  signing: Signing | null;
  created: Created | null;
};

type Action = {
  updateTestInfoForm: (formValues: State['testInfoForm']) => void;
  updateDonorInfoForm: (formValues: State['donorInfoForm']) => void;
  updateSamplingForm: (formValues: State['samplingForm']) => void;
  updateSigning: (formValues: State['signing']) => void;
  updateCreated: (formValues: State['created']) => void;
  isDirtyForm: () => boolean;
  resetFormState: () => void;
};

export type CollectionStore = State & Action;

export const defaultBreathalyzer = {
  result: '',
  enabled: false,
};

const initialFormState = {
  testInfoForm: null,
  donorInfoForm: null,
  samplingForm: {
    comment: {
      value: '',
      hasNoPrescription: false,
    },
    breathalyzer: defaultBreathalyzer,
    barcode: {
      value: '',
      confirmation: '',
    },
  },
  signing: null,
};

const initialState: State = {
  ...initialFormState,
  created: null,
};

export const useCollectionStore = create<State & Action>()((set, get) => ({
  ...initialState,
  updateTestInfoForm: (formValues) => set(() => ({ testInfoForm: formValues })),
  updateDonorInfoForm: (formValues) => set(() => ({ donorInfoForm: formValues })),
  updateSamplingForm: (formValues) => set(() => ({ samplingForm: formValues })),
  updateSigning: (values) => set(() => ({ signing: values })),
  updateCreated: (values) => set(() => ({ created: values })),
  isDirtyForm: () => get().testInfoForm !== null,
  resetFormState: () => set(() => ({ ...initialFormState })),
}));
