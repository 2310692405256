import { z } from 'zod';

export const OrganizationTypeSchema = z.enum(['workplace', 'treatment_center']);
export type OrganizationType = z.infer<typeof OrganizationTypeSchema>;
export const OrganizationType = OrganizationTypeSchema.Enum;

type SnomedCode = string;

export const SnomedOrganizationTypeCode = new Map<SnomedCode, OrganizationType>(
  [
    ['285141008', 'workplace'], // Ref: https://browser.ihtsdotools.org/?perspective=full&conceptId1=285141008&edition=MAIN&release=&languages=en
    ['20078004', 'treatment_center'], // Ref: https://browser.ihtsdotools.org/?perspective=full&conceptId1=20078004&edition=MAIN&release=&languages=en
  ],
);
