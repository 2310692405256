import {
  isDoaActivityIdentifierWithSamplingKit,
  SamplingKitIdentifierSchema,
  ToxicologyActivityIdentifierSchema,
} from '@careos/identifiers';
import { TestType } from '@careos/organization-api-types';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@careos/react-ui/Form';
import { RadioGroup, RadioGroupItem } from '@careos/react-ui/RadioGroup';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@careos/react-ui/Select';
import { SelectBox } from '@careos/react-ui/SelectBox';
import {
  DoaPanelSizeSchema,
  OrderReasonSchema,
  type GetSamplingSessionByIdResponseDto,
} from '@careos/toxicology-types';
import { KeyNameSchema } from '@careos/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import type { CollectionOrderOptions } from '../api/get-order-options';
import { usePanels } from '../api/get-panel-sizes-with-additional-substances';

import { NextButton } from './next-button';

const FormTestInfoBaseSchema = z.object({
  testType: z.literal(TestType.DoA),
  sampleType: ToxicologyActivityIdentifierSchema,
  samplingKit: SamplingKitIdentifierSchema.nullish(),
  panelSize: DoaPanelSizeSchema.exclude(['CHIRAL']).nullish(),
  additionalSubstances: z.string().array(),
});

const FormTestInfoSchema = z
  .discriminatedUnion('testType', [
    FormTestInfoBaseSchema,
    z.object({
      testType: z.literal(TestType.PEth),
    }),
  ])
  .refine(
    (val) => {
      const isSampleKitMissing =
        val.testType === TestType.DoA &&
        isDoaActivityIdentifierWithSamplingKit(val.sampleType) &&
        !val.samplingKit;
      return !isSampleKitMissing;
    },
    { message: 'Choose sampling kit', path: ['samplingKit'] },
  )
  .refine(
    (val) => {
      const isPanelMissing = val.testType === TestType.DoA && !val.panelSize;
      return !isPanelMissing;
    },
    { message: 'Choose panel size', path: ['panelSize'] },
  );

export const RequisitionFormTestInfoSchema = z.object({
  orderReason: OrderReasonSchema,
  samplingLocation: KeyNameSchema,
  testInfo: FormTestInfoSchema,
});

export type RequisitionFormTestInfo = z.infer<typeof RequisitionFormTestInfoSchema>;

type Props = {
  samplingSession: GetSamplingSessionByIdResponseDto['samplingSession'];
  onUpdate: (formVals: RequisitionFormTestInfo) => void;
  formVals: RequisitionFormTestInfo;
} & CollectionOrderOptions;

export const RequisitionFormTestInfo = ({
  enabledSampleTypeAndKits,
  employers,
  enabledTestTypes,
  formVals,
  onUpdate,
}: Props) => {
  const { t } = useTranslation();

  // TODO: The feature to toggle editing is not complete, this property is
  // quite dumb as it is now but is kept for the implementation of the
  // edit switch.
  const [isEditing] = useState(true);

  const form = useForm<RequisitionFormTestInfo>({
    resolver: zodResolver(RequisitionFormTestInfoSchema),
    defaultValues: formVals,
  });

  function onSubmit(values: RequisitionFormTestInfo) {
    onUpdate(values);
  }

  function setAdditionalSubstances(isDirty: boolean) {
    // INFO: Reset the additional substances to the default values
    // only if the user has not altered them, or when we come back
    // to that default state. Otherwise, it will create complications
    // for added substances that are not part of the new configuration.
    // Filtering the substances is an alternative, but it seems to be
    // quite complex since the options are not updated before running
    // the handling function on the panels, kits or sample type change.
    if (isDirty) {
      return form.setValue('testInfo.additionalSubstances', []);
    }
    return form.resetField('testInfo.additionalSubstances');
  }

  function handleSampleTypeChange() {
    const { isDirty } = form.getFieldState('testInfo.sampleType');
    if (isDirty) {
      form.setValue('testInfo.samplingKit', null);
      form.setValue('testInfo.panelSize', null);
    } else {
      form.resetField('testInfo.samplingKit');
      form.resetField('testInfo.panelSize');
    }
    setAdditionalSubstances(isDirty);
  }

  function handleSamplingKitChange() {
    const sampleTypeIsDirty = form.getFieldState('testInfo.sampleType').isDirty;
    const samplingKitIsDirty = form.getFieldState('testInfo.samplingKit').isDirty;
    if (sampleTypeIsDirty || samplingKitIsDirty) {
      form.setValue('testInfo.panelSize', null);
      return;
    }
    form.resetField('testInfo.panelSize');
    setAdditionalSubstances(false);
  }

  function handlePanelSizeChange() {
    const sampleTypeIsDirty = form.getFieldState('testInfo.sampleType').isDirty;
    const samplingKitIsDirty = form.getFieldState('testInfo.samplingKit').isDirty;
    const panelSizeIsDirty = form.getFieldState('testInfo.panelSize').isDirty;
    setAdditionalSubstances(panelSizeIsDirty || samplingKitIsDirty || sampleTypeIsDirty);
  }

  const watchIsDoA = form.watch('testInfo.testType') === 'DoA';
  const watchIsUrine = form.watch('testInfo.sampleType') === 'DOA_URINE_SAMPLING';
  const watchPanelSize = form.watch('testInfo.panelSize');
  const watchSamplingKit =
    form.watch('testInfo.sampleType') === 'DOA_URINE_SAMPLING'
      ? undefined
      : form.watch('testInfo.samplingKit') || undefined;

  const watchSampleType = form.watch('testInfo.sampleType');

  const enabledSampleTypes = enabledSampleTypeAndKits.map((it) => it.sampleType);
  const getEnabledSamplingKitsBySelectedSampleType = () => {
    const samplingKits =
      enabledSampleTypeAndKits.find((it) => it.sampleType === watchSampleType)?.samplingKits || [];
    return samplingKits;
  };

  const { data: panelsData } = usePanels({
    activityDefinition: watchSampleType,
    samplingKit: watchSamplingKit,
  });

  const additionalSubstancesOptions =
    (watchPanelSize &&
      panelsData?.panelsWithAdditionalSubstances[watchPanelSize].map((substance) => ({
        value: substance,
        label: t(`substances.${substance}` as any),
      }))) ||
    [];

  return (
    <div className="max-w-screen-sm">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="flex gap-x-12">
          <div className="flex min-w-[300px] flex-col gap-y-4">
            {/*
            // TODO: The feature to toggle editing is not complete. 
            // Right now, changing and proceeding in the form and going back, 
            // will cause the newly selected values to be seen as the default
            // and the edit toggle will be disabled. The user will then 
            // believe that the values in the forms are what was ordered.
            // Future development is to make sure that it works as expected,
            // having the edit toggle to be enabled when the user has changed
            // the values in the form.
            //
            <div className="flex items-center space-x-2 ml-auto">
              <Label htmlFor="edit">{t('requisition_form.edit')}</Label>
              <Switch
                checked={isEditing}
                id="edit"
                onCheckedChange={setIsEditing}
              />
            </div>
            */}
            <FormField
              control={form.control}
              name="orderReason"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('requisition_form.test_info.reason.label')}</FormLabel>
                  <Select disabled onValueChange={field.onChange} value={field.value}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue
                          placeholder={t('requisition_form.test_info.reason.placeholder')}
                        />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem key={field.value} value={field.value}>
                        {t(`reason.${field.value}` as const)}
                      </SelectItem>
                    </SelectContent>
                  </Select>

                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="samplingLocation"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('requisition_form.test_info.workplace_location.label')}</FormLabel>
                  <Select
                    disabled
                    onValueChange={(val) => {
                      const employer = employers.find((it) => it.key === val);
                      if (!employer) {
                        return;
                      }
                      field.onChange(employer);
                    }}
                    value={field.value.key}
                  >
                    <FormControl>
                      <SelectTrigger disabled={!isEditing}>
                        <SelectValue
                          placeholder={t(
                            'requisition_form.test_info.workplace_location.placeholder',
                          )}
                        />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {employers.map(({ name, key }) => (
                        <SelectItem key={key} value={key}>
                          {name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>

                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="testInfo.testType"
              render={({ field }) => (
                <FormItem className="space-y-3">
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      value={field.value}
                      disabled={!isEditing}
                      className="flex gap-x-4"
                    >
                      {enabledTestTypes.map((testType) => (
                        <FormItem className="flex flex-1 items-center" key={testType}>
                          <FormControl>
                            <>
                              <RadioGroupItem
                                value={TestType[testType]}
                                id={testType}
                                className="peer sr-only"
                              />
                              <FormLabel
                                htmlFor={testType}
                                className="w-full rounded-md border-2 border-muted bg-popover p-4 text-center hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary"
                              >
                                {t(`requisition_form.test_info.test_type.${testType}.label`)}
                              </FormLabel>
                            </>
                          </FormControl>
                        </FormItem>
                      ))}
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {watchIsDoA && (
              <>
                <FormField
                  control={form.control}
                  name="testInfo.sampleType"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('requisition_form.test_info.sample_type.label')}</FormLabel>
                      <Select
                        onValueChange={(val) => {
                          field.onChange(val);
                          handleSampleTypeChange();
                        }}
                        value={field.value}
                      >
                        <FormControl>
                          <SelectTrigger disabled={!isEditing}>
                            <SelectValue
                              placeholder={t('requisition_form.test_info.sample_type.placeholder')}
                            />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {enabledSampleTypes?.map((samplingType) => (
                            <SelectItem key={samplingType} value={samplingType}>
                              {t(`sample_type.${samplingType}`)}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>

                      <FormMessage />
                    </FormItem>
                  )}
                />

                {!watchIsUrine && (
                  <FormField
                    control={form.control}
                    name="testInfo.samplingKit"
                    render={({ field }) => {
                      const enabledSamplingKits = getEnabledSamplingKitsBySelectedSampleType();
                      return (
                        <FormItem>
                          <FormLabel>
                            {t('requisition_form.test_info.sampling_kit.label')}
                          </FormLabel>
                          <Select
                            onValueChange={(val) => {
                              field.onChange(val);
                              handleSamplingKitChange();
                            }}
                            // INFO: Setting the value to an empty string to prevent the "controlled"
                            // behavior from breaking. Setting it to either null or undefined will
                            // cause it to be "uncontrolled" and the value will not be updated.
                            value={field.value || ''}
                          >
                            <FormControl>
                              <SelectTrigger disabled={!isEditing}>
                                <SelectValue
                                  placeholder={t(
                                    'requisition_form.test_info.sampling_kit.placeholder',
                                  )}
                                />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {enabledSamplingKits.map((sampleKit) => (
                                <SelectItem key={sampleKit} value={sampleKit}>
                                  {t(`sampling_kit.${sampleKit}`)}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>

                          <FormMessage />
                        </FormItem>
                      );
                    }}
                  />
                )}
                <FormField
                  control={form.control}
                  name="testInfo.panelSize"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('requisition_form.test_info.panel.label')}</FormLabel>
                      <Select
                        onValueChange={(val) => {
                          field.onChange(val);
                          handlePanelSizeChange();
                        }}
                        value={field.value || ''}
                      >
                        <FormControl>
                          <SelectTrigger disabled={!isEditing}>
                            <SelectValue
                              placeholder={t('requisition_form.test_info.panel.placeholder')}
                            />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {panelsData?.panelSizes.map((panel) => (
                            <SelectItem key={panel} value={panel}>
                              {t(`panel.${panel}`)}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>

                      <FormMessage />
                    </FormItem>
                  )}
                />
                {additionalSubstancesOptions.length > 0 && (
                  <FormField
                    control={form.control}
                    name="testInfo.additionalSubstances"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          {t('requisition_form.test_info.additional_substances.label')}
                        </FormLabel>
                        <FormControl>
                          <SelectBox
                            className="min-h-[40px]"
                            disabled={!isEditing}
                            options={additionalSubstancesOptions}
                            value={field.value}
                            onChange={(val) => {
                              field.onChange(val);
                            }}
                            placeholder={t(
                              'requisition_form.test_info.additional_substances.placeholder',
                            )}
                            inputPlaceholder={t(
                              'requisition_form.test_info.additional_substances.search',
                            )}
                            multiple
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                )}
              </>
            )}
            <NextButton className="ml-auto mt-8 w-1/2" />
          </div>
        </form>
      </Form>
    </div>
  );
};
