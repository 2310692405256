import { z } from 'zod';
import { IdentifierSchema, IsoDateTimeSchema } from '../fhir-entities';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';
import { BasePolicySchema } from './organization-policy-updated-event';

const AccessUpdateSchema = BasePolicySchema.extend({
  /**
   * The period in seconds for which the entity should be available.
   */
  expiresAt: IsoDateTimeSchema,
  /**
   * Whether the entity has been exempted from being controlled by an expiry time.
   */
  isExempted: z.boolean(),
});

export const AccessUpdatedEventSchema = BaseEventSchema.extend({
  eventType: z.literal('AccessUpdatedEvent'),
  eventData: z.object({
    /**
     * The updated value together with an identifier denoting which type of
     * policy is being used.
     */
    accessUpdate: AccessUpdateSchema,
    /**
     * The entity for which the access has been updated, i.e. a requisition.
     */
    identifier: IdentifierSchema,
    /**
     * Timestamp of the action.
     */
    registeredAt: IsoDateTimeSchema,
  }),
});

/**
 * This event is triggered whenever the access to an entity should be altered,
 * i.e. by time-restricting it.
 */
export class AccessUpdatedEvent extends createSchemaClass(
  AccessUpdatedEventSchema,
) {}
