import { z } from 'zod';
import { TestTypeSchema } from '../../../test-type';
import { SamplingKitIdentifierSchema } from '@careos/identifiers/src/sampling-kit-identifiers';
import { ToxicologyActivityIdentifierSchema } from '@careos/identifiers/src/activity-definition-identifiers';
import { KeyNameSchema } from '@careos/types';
import { DonorInteractionTypeSchema } from '@abc-labs-ab/ts-events';

export const SampleTypeAndEnabledKitsSchema = z.object({
  sampleType: ToxicologyActivityIdentifierSchema,
  samplingKits: z.array(SamplingKitIdentifierSchema),
});
export const TestInfoSchema = z.object({
  testType: TestTypeSchema,
  sampleTypeAndEnabledKits: z.array(SampleTypeAndEnabledKitsSchema),
});

export const GetEmployersAndEnabledTestInfoResponseDtoSchema = z.object({
  employers: KeyNameSchema.array(),
  testInfo: TestInfoSchema.array(),
  donorInteractionTypes: DonorInteractionTypeSchema.array().min(1),
});

export type GetEmployersAndEnabledTestInfoResponseDto = z.infer<
  typeof GetEmployersAndEnabledTestInfoResponseDtoSchema
>;
