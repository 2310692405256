import { z } from 'zod';

export const DonorRemoteInteractionTypeSchema = z.enum(['video_at_workplace']);
export type DonorRemoteInteractionType = z.infer<
  typeof DonorRemoteInteractionTypeSchema
>;

export const DonorInPersonInteractionTypeSchema = z.enum([
  'at_workplace',
  'at_abc_labs',
]);
export type DonorInPersonInteractionType = z.infer<
  typeof DonorInPersonInteractionTypeSchema
>;

export const DonorInteractionTypeSchema = z.enum([
  ...DonorRemoteInteractionTypeSchema.options,
  ...DonorInPersonInteractionTypeSchema.options,
]);
export const DonorInteractionType = DonorInteractionTypeSchema.Enum;
export type DonorInteractionType = z.infer<typeof DonorInteractionTypeSchema>;
