import { Card, CardContent, CardHeader, CardTitle } from '@careos/react-ui/Card';
import { PdfRender } from '@careos/react-ui/PdfRender';
import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute, useBlocker } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { useConfirmRequisition } from '@/features/order/form/api/create-confirm-requisition';
import { useSubmitRequisition } from '@/features/order/form/api/create-submit-requisition';
import { getSignedRequisitionByTransactionIdOptions } from '@/features/order/form/api/get-signed-pdf-by-transaction-id';
import { BackButton } from '@/features/order/form/components/back-button';
import { RequisitionFormSignedGoBackPrompt } from '@/features/order/form/components/requisition-form-signed-go-back-prompt';
import { RequisitionSubmitModal } from '@/features/order/form/components/requisition-submit-modal';
import { useCollectionStore } from '@/features/order/form/stores/collection-store';
import { assertDefined } from '@/utils/assert-defined';
export const Route = createFileRoute('/_authenticated/sessions/$sessionId/signed')({
  beforeLoad: ({ context }) => {
    return assertDefined(context.collectionStore.signing, 'Missing signing data');
  },
  loader: async ({ context }) => {
    await context.queryClient.ensureQueryData(
      getSignedRequisitionByTransactionIdOptions(context.transactionId),
    );
  },
  component: RouteComponent,
});

function RouteComponent() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'requisition_form.signing',
  });
  const navigate = Route.useNavigate();
  const { transactionId, collectionStore } = Route.useRouteContext();
  const updateCreated = useCollectionStore((state) => state.updateCreated);

  const { data: pdf } = useSuspenseQuery(getSignedRequisitionByTransactionIdOptions(transactionId));

  const submitRequisitionMutation = useSubmitRequisition({
    mutationConfig: {
      onSuccess: (data) => {
        updateCreated({
          donorPassword: assertDefined(data.donorPassword),
          pdf,
        });
        confirmRequisitionMutation.mutate({
          collectorConfirmationText: t('collector_confirm_modal.approval'),
          requisitionId: data.requisitionId,
        });
      },
      meta: { invalidates: [] },
    },
  });

  const confirmRequisitionMutation = useConfirmRequisition({
    mutationConfig: {
      onSuccess: () => {
        collectionStore.resetFormState();
        navigate({ to: '../created' });
      },
      meta: { invalidates: [] },
    },
  });

  const handleSubmitConfirmation = () => {
    submitRequisitionMutation.mutate({
      transactionId,
    });
  };

  const { proceed, reset, status } = useBlocker({
    shouldBlockFn: ({ next }) => next.fullPath === '/sessions/$sessionId/sign',
    withResolver: true,
    enableBeforeUnload: true,
  });

  return (
    <Card className="mx-auto mt-8 w-fit">
      <CardHeader>
        <CardTitle className="mx-auto">{t('title')}</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex w-[800px] flex-col gap-y-4">
          <PdfRender pdfSource={pdf} />
          <RequisitionSubmitModal onSubmitConfirmation={handleSubmitConfirmation} />
          <BackButton onClick={() => navigate({ to: '../sign' })} />
        </div>
        {status === 'blocked' && (
          <RequisitionFormSignedGoBackPrompt
            onOpenChange={() => reset()}
            isOpen={status === 'blocked'}
            onAction={() => proceed()}
          />
        )}
      </CardContent>
    </Card>
  );
}
