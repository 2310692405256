import { AlertTriangle } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from '#components/Alert/Alert';
import { Button } from '#components/Button/Button';
import { Link, useRouter } from '@tanstack/react-router';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '#components/Card/Card';
import { useTranslation } from 'react-i18next';
import {
  ErrorComponentSentry,
  type ErrorComponentSentryProps,
} from './ErrorComponentSentry';

type ErrorComponentSentryDefault = Pick<ErrorComponentSentryProps, 'error'> & {
  errorDescription?: string;
};

export const ErrorComponentSentryDefault = ({
  errorDescription,
  error,
}: ErrorComponentSentryDefault) => {
  const { t } = useTranslation('translation', { keyPrefix: 'generic' });
  const router = useRouter();
  const defaultErrorDescription = errorDescription || t('error.message');

  const handleInvalidateRouter = () => {
    router.invalidate();
  };

  return (
    <ErrorComponentSentry error={error}>
      <Card className="h-fit w-full max-w-xl" data-testid="error-fallback">
        <CardHeader>
          <CardTitle className="flex items-center gap-2 text-destructive">
            <AlertTriangle className="size-6" />
            {t('error.message')}
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <Alert variant="destructive">
            <AlertTitle>{t('error.title')}</AlertTitle>
            <AlertDescription>{defaultErrorDescription}</AlertDescription>
          </Alert>
          <p className="text-sm text-muted-foreground">{t('error.action')}</p>
        </CardContent>
        <CardFooter className="flex justify-between">
          <Button variant="outline" asChild>
            <Link to="/">{t('navigation.goToStartPage')}</Link>
          </Button>
          <Button onClick={handleInvalidateRouter}>{t('retry')}</Button>
        </CardFooter>
      </Card>
    </ErrorComponentSentry>
  );
};
