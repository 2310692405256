import { type PendingResponseDto } from '@careos/types';
import { QRCode } from './QRCode';
import { useTranslation } from 'react-i18next';

export const QRScanPending = ({ hintCode, qrText }: PendingResponseDto) => {
  const { t } = useTranslation('translation', { keyPrefix: 'bankid' });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const hint = t([`hintcode-qr-${hintCode}`, `hintcode-${hintCode}`] as any);

  if (hintCode === 'userSign') {
    return <p>{t('hintcode-userSign')}</p>;
  }

  return (
    <>
      <p className="mb-4">{hint}</p>
      <QRCode qrCode={qrText} />
    </>
  );
};
