import { z } from 'zod';
import { BaseEventSchema } from './base-event';
import { createSchemaClass } from '../utils';
import {
  IdentifierSchema,
  IsoDateTimeSchema,
  ReferenceSchema,
} from '../fhir-entities';

export const RandomizationListFileUploadedEventSchema = BaseEventSchema.extend({
  eventType: z.literal('RandomizationListFileUploadedEvent'),
  eventData: z.object({
    /**
     * Timestamp of when the file was uploaded.
     */
    uploadedAt: IsoDateTimeSchema,

    /**
     * Reference to the session
     */
    session: IdentifierSchema,

    /**
     * The file name of the uploaded randomization list.
     */
    fileName: z.string(),

    /**
     * Name of the file on the uploader's computer.
     */
    originalFileName: z.string(),

    /**
     * The file type of the uploaded randomization list.
     */
    mimeType: z.string(),

    /**
     * The file version of the uploaded randomization list.
     */
    fileVersion: z.string(),

    /**
     * Location of where the file is stored
     */
    fileStorageLocation: IdentifierSchema,

    /**
     * {@link Practitioner} who authorized the upload.
     */
    authorizedBy: ReferenceSchema(z.literal('Practitioner')),
  }),
});

/**
 * A randomization list file was uploaded for a sampling session
 */
export class RandomizationListFileUploadedEvent extends createSchemaClass(
  RandomizationListFileUploadedEventSchema,
) {}
