import { createSchemaClass, IdentifierSchema } from '@abc-labs-ab/ts-events';
import { z } from 'zod';

export const CustomerFileUploadResponseDtoSchema = z.object({
  /**
   * The file version of the uploaded file.
   */
  fileVersion: z.string(),

  /**
   * Location of where the file is stored
   */
  fileStorageLocation: IdentifierSchema,
});

export class CustomerFileUploadResponseDto extends createSchemaClass(
  CustomerFileUploadResponseDtoSchema,
) {}
