import { z } from 'zod';

import { PersonalIdentityNumberSchema } from '@careos/toxicology-types';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '#components/Form/Form';

import { SwedishPersonalNumberInput } from './SwedishPersonalNumberInput';

import type { FieldValues } from 'react-hook-form';
import type { FormFieldBaseProps } from '../types/FormField';

export const SwedishPersonalNumberFieldSchema = z.object({
  swedishPersonalNumber: PersonalIdentityNumberSchema,
});

type SwedishPersonalNumberFieldProps<T extends FieldValues> = Omit<
  FormFieldBaseProps<T>,
  'label'
> & { label?: string };

export const SwedishPersonalNumberField = <T extends FieldValues>({
  control,
  optional,
  label,
  name,
}: SwedishPersonalNumberFieldProps<T>) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'generic.form.swedishPersonalNumber',
  });

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel optional={optional}>{label ?? t('label')}</FormLabel>
          <FormControl>
            <SwedishPersonalNumberInput {...field} value={field.value || ''} />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
