import { z } from 'zod';

export const InitiateRemoteSigningResponseDtoSchema = z.object({
  transactionId: z.string().uuid(),
  pinCode: z.string().length(4),
  signingLink: z.string().url().optional(),
});

export type InitiateRemoteSigningResponseDto = z.infer<
  typeof InitiateRemoteSigningResponseDtoSchema
>;
