import { Button } from '#components/Button/Button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
  DropdownMenuItem,
} from '#components/DropdownMenu/DropdownMenu';

import {
  LanguageSelector,
  type LanguageSelectorProps,
} from './LanguageSelector';
import { LogOut } from './Logout';
import { UserEmail } from './UserEmail';
import type { Auth0CareosUser } from '../AuthenticatedComponent';
import { type NavigationItemsProps } from './NavigationItems';
import { Link } from '@tanstack/react-router';
import { useState } from 'react';

export type UserMenuProps = {
  user: Auth0CareosUser;
  onLogout: () => void;
  menuItems?: () => React.ReactNode;
} & Partial<LanguageSelectorProps> &
  Partial<Omit<NavigationItemsProps, 'className'>>;

export const UserMenu = ({
  onLogout,
  user,
  onLanguageChange,
  supportedLanguages = ['sv', 'en'],
  menuItems,
  linkOptions,
}: UserMenuProps) => {
  const [open, setOpen] = useState(false);

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className="rounded-full">
          {getUserInitial(user)}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <div className="sm:hidden">
          {linkOptions && (
            <>
              {linkOptions.map(({ to, ...option }) => (
                <DropdownMenuItem className="ml-6" asChild key={to}>
                  <Link to={to} onClick={() => setOpen(false)} {...option}>
                    {option.label}
                  </Link>
                </DropdownMenuItem>
              ))}
              <DropdownMenuSeparator />
            </>
          )}
        </div>
        {supportedLanguages.length > 1 && (
          <LanguageSelector
            onLanguageChange={onLanguageChange}
            supportedLanguages={supportedLanguages}
          />
        )}
        {menuItems && menuItems()}
        <LogOut onLogout={onLogout} />
        <DropdownMenuSeparator />
        <UserEmail email={user.email} />
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const getUserInitial = (user: Auth0CareosUser) =>
  user.name.at(0)?.toUpperCase();
