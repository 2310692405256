import { type FailedResponseDto } from '@careos/types';
import { useTranslation } from 'react-i18next';
import type { FlowType } from './BankId';

export type ResultFailedActions = {
  onTryAgain: () => void;
  onCancel: () => void;
};

type ResultFailedProps = ResultFailedActions &
  FailedResponseDto & { flowType: FlowType };

export const ResultFailed = ({
  hintCode,
  onCancel,
  onTryAgain,
  flowType,
}: ResultFailedProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'bankid' });

  const hint = t([
    `hintcode-${flowType}-${hintCode}`,
    `hintcode-failed-${hintCode}`,
    `hintcode-${hintCode}`,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ] as any);

  return (
    <div className="flex flex-col gap-y-5 text-lg h-full justify-between">
      <p>{hint}</p>
      <div className="flex flex-col gap-y-5 h-full justify-self-end">
        <button className="bg-black px-8 py-4 text-white" onClick={onTryAgain}>
          {t('try-again')}
        </button>
        <button onClick={onCancel}>{t('cancel')}</button>
      </div>
    </div>
  );
};
