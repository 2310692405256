import { createFileRoute, notFound } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { ErrorAlert } from '@/components/error-alert';
import { getDonorIsTransactionValidOptions } from '@/features/order/form/api/get-donor-is-transaction-valid';

export const Route = createFileRoute('/donor/$transactionId')({
  loader: async ({ context, params }) => {
    const data = await context.queryClient.fetchQuery(
      getDonorIsTransactionValidOptions(params.transactionId),
    );

    if (!data.valid) {
      throw notFound();
    }
  },
  notFoundComponent: NotFoundComponent,
});

function NotFoundComponent() {
  const { t } = useTranslation();
  return (
    <div className="p-2">
      <ErrorAlert title={t('requisition_form.signing.donor.session_expired')} />
    </div>
  );
}
