import { createFileRoute } from '@tanstack/react-router';

import { ErrorAlert } from '@/components/error-alert';
import { useDonorSignRequisition } from '@/features/order/form/api/create-donor-sign-requisition';
import { SignaturePad } from '@/features/order/form/components/signature-pad';
import { getSignatureDimensions } from '@/features/order/form/helpers/get-signature-dimensions';

export const Route = createFileRoute('/donor/$transactionId/sign-consent/handwritten')({
  component: RouteComponent,
});

function RouteComponent() {
  const navigate = Route.useNavigate();
  const context = Route.useRouteContext();
  const params = Route.useParams();

  const donorSignMutation = useDonorSignRequisition({
    mutationConfig: {
      onSuccess: () => navigate({ to: '/donor/success' }),
    },
  });

  const handleSignatureSubmit = async (signature: string) => {
    donorSignMutation.mutate({
      pinCode: context.remoteSigning.pinCode,
      signature: {
        signatureType: 'HANDWRITTEN',
        dimensions: await getSignatureDimensions(signature),
        signatureData: signature,
      },
      transactionId: params.transactionId,
    });
  };

  if (donorSignMutation.isError) {
    return (
      <div className="p-2">
        <ErrorAlert />
      </div>
    );
  }

  return (
    <div className="flex h-dvh">
      <SignaturePad
        onSubmitSignature={handleSignatureSubmit}
        loading={donorSignMutation.isPending}
        className="my-auto"
      />
    </div>
  );
}
