import { z } from 'zod';
import { IdentifierSchema, IsoDateTimeSchema } from '../fhir-entities';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';
import { PolicyManagedRequisitionSchema } from './organization-policy-exempted-event';

export const OrganizationPolicyUnexemptedEventSchema = BaseEventSchema.extend({
  eventType: z.literal('OrganizationPolicyUnexemptedEvent'),
  eventData: z.object({
    /**
     * The target of an unexemption, including which type of policy
     * it is unexempting.
     */
    target: PolicyManagedRequisitionSchema,
    /**
     * Reference to the user who took the action.
     */
    user: IdentifierSchema,
    /**
     * Timestamp of the action.
     */
    registeredAt: IsoDateTimeSchema,
  }),
});

/**
 * This event is triggered when an entity within an organization should be
 * unexempted from a previous exemption.
 */
export class OrganizationPolicyUnexemptedEvent extends createSchemaClass(
  OrganizationPolicyUnexemptedEventSchema,
) {}
