import { z } from 'zod';

export const SnomedSpecimenTypeIdentifierSchema = z.nativeEnum({
  Saliva: '119342007',
  Blood: '119294007',
  Urine: '122575003',
});
export const SnomedSpecimenTypeIdentifier =
  SnomedSpecimenTypeIdentifierSchema.enum;
export type SnomedSpecimenTypeIdentifier = z.infer<
  typeof SnomedSpecimenTypeIdentifierSchema
>;
