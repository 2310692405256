import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@careos/react-ui/Form';
import { InputOTP, InputOTPGroup, InputOTPSlot } from '@careos/react-ui/InputOTP';
import { Spinner } from '@careos/react-ui/Spinner';
import { zodResolver } from '@hookform/resolvers/zod';
import { createFileRoute, useRouter } from '@tanstack/react-router';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { ErrorAlert } from '@/components/error-alert';
import { useDonorGetRequisition } from '@/features/order/form/api/create-donor-get-requisition';

export const Route = createFileRoute('/donor/$transactionId/pin-code')({
  errorComponent: () => (
    <div className="p-2">
      <ErrorAlert />
    </div>
  ),
  component: DonorSigningComponent,
});

function DonorSigningComponent() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'requisition_form.signing',
  });
  const params = Route.useParams();
  const navigate = Route.useNavigate();
  const router = useRouter();

  const getRequisitionMutation = useDonorGetRequisition({
    mutationConfig: {
      onError: (error) => {
        // TODO: make error type safe
        if (error.statusCode === 401) {
          form.reset();
          form.setError('pin', {
            message: t(
              `donor.error.${error.data.error as 'EXPIRED' | 'INVALID'}`, // TODO: replace this with when error object is typed
            ),
          });
        }
      },
      onSuccess: ({ pdf }, { pinCode }) => {
        router.options.context.remoteSigning = { pdf, pinCode };
        navigate({ to: '../sign-consent' });
      },
    },
  });

  const FormSchema = z.object({
    pin: z.string().length(4),
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      pin: '',
    },
  });

  const onSubmit = (data: z.infer<typeof FormSchema>) => {
    getRequisitionMutation.mutate({
      transactionId: params.transactionId,
      pinCode: data.pin,
    });
  };

  if (getRequisitionMutation.isPending) {
    return (
      <div className="flex h-dvh items-center justify-center">
        <Spinner size="lg" />
      </div>
    );
  }

  return (
    <div className="flex h-dvh items-center justify-center px-2">
      <Form {...form}>
        <form className="space-y-6 rounded-xl bg-white p-4 ">
          <FormField
            control={form.control}
            name="pin"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('donor.otp_label')}</FormLabel>
                <FormControl>
                  <InputOTP
                    autoComplete="off"
                    onComplete={form.handleSubmit(onSubmit)}
                    maxLength={4}
                    {...field}
                  >
                    <InputOTPGroup>
                      <InputOTPSlot index={0} />
                      <InputOTPSlot index={1} />
                      <InputOTPSlot index={2} />
                      <InputOTPSlot index={3} />
                    </InputOTPGroup>
                  </InputOTP>
                </FormControl>
                <FormDescription>{t('donor.otp_description')}</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
        </form>
      </Form>
    </div>
  );
}
