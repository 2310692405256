import { IdentifierSystem } from '@abc-labs-ab/ts-events';
import Personnummer from 'personnummer';
import { z } from 'zod';

export const PersonalIdentityNumberSchema = z
  .string()
  .refine((value) => Personnummer.valid(value), {
    message: 'Invalid personal number',
  });

export const PersonalIdentityNumberIdentifierSchema = z.object({
  system: z.literal(IdentifierSystem.PersonalIdentityNumber),
  value: PersonalIdentityNumberSchema,
});

export type PersonalIdentityNumber = z.infer<
  typeof PersonalIdentityNumberSchema
>;
export type PersonalIdentityNumberIdentifier = z.infer<
  typeof PersonalIdentityNumberIdentifierSchema
>;
