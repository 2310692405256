import { Card, CardContent, CardHeader, CardTitle } from '@careos/react-ui/Card';
import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import {
  RequisitionFormSampling,
  type SamplingForm,
} from '@/features/order/form/components/requisition-form-sampling';
import { useCollectionStore } from '@/features/order/form/stores/collection-store';

export const Route = createFileRoute('/_authenticated/sessions/$sessionId/sampling')({
  component: RouteComponent,
});

function RouteComponent() {
  const navigate = Route.useNavigate();
  const { t } = useTranslation('translation', {
    keyPrefix: 'requisition_form.sampling',
  });

  const testInfoForm = useCollectionStore((state) => state.testInfoForm);
  const samplingForm = useCollectionStore((state) => state.samplingForm);
  const updateSamplingForm = useCollectionStore((state) => state.updateSamplingForm);

  const testInfo = testInfoForm?.testInfo;
  const isDoASampling = testInfo?.testType === 'DoA';
  const isSampleTypeUrine = isDoASampling && testInfo.sampleType === 'DOA_URINE_SAMPLING';

  const handleSamplingUpdate = (
    samplingFormValues: SamplingForm,
    stepDirection: 'previous' | 'next',
  ) => {
    updateSamplingForm(samplingFormValues);
    if (stepDirection === 'next') {
      navigate({ to: '../sign' });
    } else {
      navigate({ to: '../donor-info' });
    }
  };

  return (
    <Card className="mx-auto mt-8 w-fit">
      <CardHeader>
        <CardTitle className="mx-auto">{t('title')}</CardTitle>
      </CardHeader>
      <CardContent>
        <RequisitionFormSampling
          onUpdate={handleSamplingUpdate}
          formVals={samplingForm}
          isSampleTypeUrine={isSampleTypeUrine}
          isDoASampling={isDoASampling}
        />
      </CardContent>
    </Card>
  );
}
