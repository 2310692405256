import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { InputField, type InputFieldBaseProps } from './InputField';
import type { FieldValues } from 'react-hook-form';

export const EmailSchema = z.string().email();

export const EmailField = <T extends FieldValues>({
  ...props
}: InputFieldBaseProps<T>) => {
  const { t } = useTranslation();
  return <InputField label={t('generic.form.email.label')} {...props} />;
};
