import { z } from 'zod';
import {
  AnnotationSchema,
  CodeableReferenceSchema,
  IdentifierSchema,
  OrganizationSchema,
  PatientSchema,
  ReferenceSchema,
  ReferenceTypeSchema,
} from '../fhir-entities';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

export const SamplingDetailsSchema = z.object({
  /**
   * Test type that is being ordered. eg DoA, PEth.
   */
  testType: z.string(),
  /**
   * Sample type that is being ordered. eg urine, blood, saliva.
   */
  sampleType: ReferenceSchema(z.literal('ActivityDefinition')).optional(),
  /**
   * The equipment used to collect the sample.
   */
  samplingKit: IdentifierSchema.optional(),
  /**
   * The panel size that the sample should be tested for.
   */
  panelSize: z.string().optional(),
  /**
   * Additional substances that the sample should be tested for.
   */
  additionalSubstances: z.array(z.string()).optional(),
});

export type SamplingDetails = z.infer<typeof SamplingDetailsSchema>;

export const BaseWorkplaceTestingOrderSchema = z.object({
  /**
   * The organization that requested the order.
   */
  requester: OrganizationSchema,
  /**
   * The workplace of the requester for which the order is for.
   */
  workplace: OrganizationSchema,
  /**
   * An explanation or justification for why this service is being requested.
   */
  reason: CodeableReferenceSchema(ReferenceTypeSchema),
  /**
   * Date period during which the sampling should be performed.
   */
  samplingPeriod: z.object({
    /**
     * Start date for when sampling can be performed.
     */
    start: z.string(),
    /**
     * End date for when sampling can be performed.
     */
    end: z.string(),
  }),
  /**
   * Time slot during which the sampling should be performed.
   */
  samplingTimeSlot: z.string().optional(),
  /**
   * Any other notes or comment made about the order.
   */
  notes: AnnotationSchema.optional(),
  /**
   * Details of the sampling that is being requested.
   */
  samplingDetails: z.array(SamplingDetailsSchema),
  /**
   * The requested type of interaction between the donor and collector during sampling. {@link DonorInteractionType}.
   */
  donorInteractionType: z.string().optional(),
});

export type BaseWorkplaceTestingOrder = z.infer<
  typeof BaseWorkplaceTestingOrderSchema
>;

export const RandomWorkplaceTestingOrderSchema =
  BaseWorkplaceTestingOrderSchema.extend({
    // For now, this object is empty because it's identical to BaseSamplingOrderRequestedEventSchema
    // In the future, properties that are specific to RandomSamplingOrderRequestedEventSchema can be added here.
  });

export type RandomWorkplaceTestingOrder = z.infer<
  typeof RandomWorkplaceTestingOrderSchema
>;

export const isRandomWorkplaceTestingOrder = (
  order: unknown,
): order is RandomWorkplaceTestingOrder =>
  RandomWorkplaceTestingOrderSchema.safeParse(order).success;

export const DonorWorkplaceTestingOrderSchema =
  BaseWorkplaceTestingOrderSchema.extend({
    /**
     * The donor that the order is for.
     */
    donor: PatientSchema,
  });

export type DonorWorkplaceTestingOrder = z.infer<
  typeof DonorWorkplaceTestingOrderSchema
>;

export const isDonorWorkplaceTestingOrder = (
  order: unknown,
): order is DonorWorkplaceTestingOrder =>
  DonorWorkplaceTestingOrderSchema.safeParse(order).success;

export const WorkplaceTestingOrderSchema = z.union([
  DonorWorkplaceTestingOrderSchema,
  RandomWorkplaceTestingOrderSchema,
]);

export type WorkplaceTestingOrder = z.infer<typeof WorkplaceTestingOrderSchema>;

export const WorkplaceTestingRequestedEventSchema = BaseEventSchema.extend({
  eventType: z.literal('WorkplaceTestingRequestedEvent'),
  eventData: z.object({
    /**
     * Unique identifier for the order.
     */
    orderId: z.string().uuid(),
    /**
     * The order that was requested.
     */
    order: WorkplaceTestingOrderSchema,
  }),
});

/**
 * A workplace testing has been requested.
 */
export class WorkplaceTestingRequestedEvent extends createSchemaClass(
  WorkplaceTestingRequestedEventSchema,
) {}
