import { AssessmentSchema, BarcodeSchema } from '@abc-labs-ab/ts-events';
import { ToxicologyActivityIdentifierSchema } from '@careos/identifiers';
import { TestTypeSchema } from '@careos/organization-api-types';
import { z } from 'zod';
import { ReasonSchema } from '../../../common';
import { MedicallyAssessmentStatusSchema } from '../types/mro-result-status';

const ObservationSchema = z.object({
  observationId: z.string(),
  observationDefinitionId: z.string(),
  assessment: AssessmentSchema.nullish(),
});

export const ReviewProjectionSchema = z.object({
  requisitionId: z.string(),
  barcode: BarcodeSchema,
  status: MedicallyAssessmentStatusSchema,
  reasonForTesting: ReasonSchema,
  sampleType: ToxicologyActivityIdentifierSchema,
  testType: TestTypeSchema,
  personId: z.string(),
  personName: z.string(),
  sampledAt: z.string(),
  remark: z
    .object({
      body: z.string(),
      id: z.string(),
    })
    .optional(),
  observations: z.array(ObservationSchema).min(1),
  breathalyzerResult: z.string().nullish(),
});

export const MedicalApprovalProjectionSchema = z.object({
  requisitionId: z.string(),
  barcode: z.string(),
  requisitionReceivedAt: z.string(),
  specimenType: ToxicologyActivityIdentifierSchema,
  testType: TestTypeSchema,
  reasonForTesting: z.string(),
  resultReceivedAt: z.string(),
  status: MedicallyAssessmentStatusSchema,
});

export type MedicalApprovalResults = z.infer<
  typeof MedicalApprovalProjectionSchema
>;
export type ResultReview = z.infer<typeof ReviewProjectionSchema>;
export type Observation = z.infer<typeof ObservationSchema>;

export type MedicalApprovalResultsResponseDto = {
  results: MedicalApprovalResults[];
};
export type ResultReviewResponseDto = {
  result: ResultReview;
};
