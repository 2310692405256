import type { SamplingKitIdentifier, ToxicologyActivityIdentifier } from '@careos/identifiers';
import type { GetEmployersAndEnabledTestInfoResponseDto } from '@careos/organization-api-types';
import { httpClient, tokenlessHttpClient } from '@careos/react-ui/utils';
import type {
  RandomizationListFileResponseDto,
  RandomizationListUrlResponseDto,
  BankIdSignRequestDto,
  ConfirmRequisitionRequestDto,
  CreateOrderResponseDto,
  DonorBankIdSignRequestDto,
  DonorCheckBankidSigningRequestDto,
  DonorGetRequisitionByTransactionIdRequestDto,
  DonorHandwrittenSignatureRequestDto,
  FinishSamplingSessionRequestDtoType,
  FinishSamplingSessionResponseDto,
  GenerateRandomNumberSeriesRequestDto,
  GenerateRandomNumberSeriesResponseDto,
  GenerateRequisitionPdfResponseDto,
  GenerateRequisitionRequestDto,
  GetPanelSizeWithAdditionalSubstancesResponseDto,
  GetSamplingSessionByIdResponseDto,
  GetSamplingSessionsResponseDto,
  HandwrittenSignRequestDto,
  InitiateRemoteSigningRequestDto,
  InitiateRemoteSigningResponseDto,
  IsTransactionValidResponseDto,
  StartSamplingSessionRequestDtoType,
  StartSamplingSessionResponseDto,
  SubmitOrderRequestDto,
} from '@careos/toxicology-types';
import type {
  BankIdCheckResponseDto,
  BankIdRequestErrorResponseDto,
  BankIdSignResponseDto,
} from '@careos/types';

const REQUISITIONS = '/requisitions';
const SAMPLING_SESSION = '/sampling-session';
const SAMPLING_SESSIONS = '/sampling-sessions';
const CONFIG = '/config';

const bankId = {
  sign: (body: Omit<BankIdSignRequestDto, 'userAgent'>) =>
    httpClient<BankIdSignResponseDto | BankIdRequestErrorResponseDto>(
      `${REQUISITIONS}/bankid-initiate-sign-requisition`,
      {
        method: 'POST',
        body,
      },
    ),

  check: (orderRef: string) =>
    httpClient<BankIdCheckResponseDto | BankIdRequestErrorResponseDto>(
      `${REQUISITIONS}/check-bankid-signing/${orderRef}`,
      { method: 'POST' },
    ),

  cancel: (orderRef: string) =>
    httpClient<void | BankIdRequestErrorResponseDto>(
      `${REQUISITIONS}/cancel-bankid-signing/${orderRef}`,
      { method: 'DELETE' },
    ),

  donorSign: (body: Omit<DonorBankIdSignRequestDto, 'userAgent'>) =>
    tokenlessHttpClient<BankIdSignResponseDto | BankIdRequestErrorResponseDto>(
      `${REQUISITIONS}/donor-initiate-bankid-signing`,
      {
        method: 'POST',
        body,
      },
    ),

  donorCheck: (body: DonorCheckBankidSigningRequestDto) =>
    tokenlessHttpClient<BankIdCheckResponseDto | BankIdRequestErrorResponseDto>(
      `${REQUISITIONS}/donor-check-bankid-signing`,
      {
        method: 'POST',
        body,
      },
    ),

  donorCancel: (body: DonorCheckBankidSigningRequestDto) =>
    tokenlessHttpClient<void | BankIdRequestErrorResponseDto>(
      `${REQUISITIONS}/donor-cancel-bankid-signing`,
      {
        method: 'DELETE',
        body,
      },
    ),
};

const requisition = {
  cancelRequest: (transactionId: string) =>
    httpClient<void>(`${REQUISITIONS}/requisition-request/${transactionId}`, {
      method: 'DELETE',
    }),

  checkBarcode: (barcode: string) =>
    httpClient(`${REQUISITIONS}/check-barcode`, {
      method: 'POST',
      body: { barcode },
    }),

  confirm: (dto: ConfirmRequisitionRequestDto) =>
    httpClient(`${REQUISITIONS}/confirm-requisition`, {
      method: 'POST',
      body: dto,
    }),

  donorGetRequisitionByTransactionId: (dto: DonorGetRequisitionByTransactionIdRequestDto) =>
    tokenlessHttpClient<GenerateRequisitionPdfResponseDto>(
      `${REQUISITIONS}/donor-get-requisition-by-transaction-id`,
      {
        method: 'POST',
        body: dto,
      },
    ),

  donorSign: (dto: DonorHandwrittenSignatureRequestDto) =>
    tokenlessHttpClient<void>(`${REQUISITIONS}/donor-hand-sign-requisition`, {
      method: 'POST',
      body: dto,
    }),

  generate: (dto: GenerateRequisitionRequestDto) =>
    httpClient<GenerateRequisitionPdfResponseDto>(`${REQUISITIONS}/generate-requisition`, {
      method: 'POST',
      body: dto,
    }),

  initiateRemoteSigning: (dto: InitiateRemoteSigningRequestDto) =>
    httpClient<InitiateRemoteSigningResponseDto>(`${REQUISITIONS}/initiate-remote-signing`, {
      method: 'POST',
      body: dto,
    }),

  sign: (dto: HandwrittenSignRequestDto) =>
    httpClient<void>(`${REQUISITIONS}/hand-sign-requisition`, {
      method: 'POST',
      body: dto,
    }),

  submit: (dto: SubmitOrderRequestDto) =>
    httpClient<CreateOrderResponseDto>(`${REQUISITIONS}/submit`, {
      method: 'POST',
      body: dto,
    }),

  donorIsTransactionValid: (transactionId: string) =>
    tokenlessHttpClient<IsTransactionValidResponseDto>(
      `${REQUISITIONS}/donor-is-transaction-valid/${transactionId}`,
    ),

  getSignedRequisitionByTransactionId: (transactionId: string) =>
    httpClient<string>(`${REQUISITIONS}/get-signed-pdf-by-transaction-id/${transactionId}`),
};

const config = {
  getOrderOptions: () =>
    httpClient<GetEmployersAndEnabledTestInfoResponseDto>(`${CONFIG}/get-order-options`),

  getPanelSizesWithAdditionalSubstances: (
    activityDefinition: ToxicologyActivityIdentifier,
    samplingKit?: SamplingKitIdentifier,
  ) =>
    httpClient<GetPanelSizeWithAdditionalSubstancesResponseDto>(
      `${CONFIG}/get-panels-with-additional-substances`,
      {
        query: {
          activityDefinition,
          samplingKit,
        },
      },
    ),
};

const samplingSession = {
  create: (body: StartSamplingSessionRequestDtoType) =>
    httpClient<StartSamplingSessionResponseDto>(`${SAMPLING_SESSION}/start-sampling-session`, {
      method: 'POST',
      body,
    }),

  finish: (body: FinishSamplingSessionRequestDtoType) =>
    httpClient<FinishSamplingSessionResponseDto>(`${SAMPLING_SESSION}/finish-sampling-session`, {
      method: 'POST',
      body,
    }),

  createRandomizationListUploadUrl: (sessionId: string) =>
    httpClient<RandomizationListUrlResponseDto>(`/randomization-list/upload-url/${sessionId}`, {
      method: 'POST',
      body: {},
    }),

  getRandomizationListFile: (sessionId: string) =>
    httpClient<RandomizationListFileResponseDto>(`/randomization-list/file/${sessionId}`),
  createNumbersSeries: ({
    sessionId,
    body,
  }: {
    sessionId: string;
    body: GenerateRandomNumberSeriesRequestDto;
  }) =>
    httpClient<GenerateRandomNumberSeriesResponseDto>(
      `${SAMPLING_SESSIONS}/${sessionId}/random-number-series`,
      { method: 'POST', body },
    ),
};

// TODO: add whatever is under samplingSession(s) to the other and have one endpoint
const samplingSessions = {
  get: () =>
    httpClient<GetSamplingSessionsResponseDto>(`${SAMPLING_SESSIONS}/get-sampling-sessions`),

  getById: (sessionId: string) =>
    httpClient<GetSamplingSessionByIdResponseDto>(
      `${SAMPLING_SESSIONS}/get-sampling-session/${sessionId}`,
    ),
};

export const api = {
  bankId,
  requisition,
  config,
  samplingSession,
  samplingSessions,
};
