import { z } from 'zod';

export const CreateRequisitionAccessPolicyExemptionRequestDtoSchema = z.object({
  requisitionId: z.string(),
  userId: z.string(),
});

export type CreateRequisitionAccessPolicyExemptionRequestDto = z.infer<
  typeof CreateRequisitionAccessPolicyExemptionRequestDtoSchema
>;
