import { createFileRoute } from '@tanstack/react-router';

import { BankIdSameDevice } from '@/features/bank-id/components/bank-id-same-device';

export const Route = createFileRoute('/donor/$transactionId/sign-consent/bank-id')({
  component: RouteComponent,
});

function RouteComponent() {
  const navigate = Route.useNavigate();
  const context = Route.useRouteContext();
  const params = Route.useParams();

  // TODO: We should check the device and render corresponding BankID component and flowType
  return (
    <BankIdSameDevice
      pinCode={context.remoteSigning.pinCode}
      onCancel={() => navigate({ to: '..' })}
      onCompletion={() => navigate({ to: '/donor/success' })}
      transactionId={params.transactionId}
    />
  );
}
