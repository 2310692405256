import { nonEmptyString } from '@careos/types';
import { z } from 'zod';

export const AuthenticatedLinkAccountsRequestDtoSchema = z.object({
  secondaryAccountId: nonEmptyString,
});

export type AuthenticatedLinkAccountsRequestDto = z.infer<
  typeof AuthenticatedLinkAccountsRequestDtoSchema
>;
