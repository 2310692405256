import { useAuth0 } from '@auth0/auth0-react';
import { createHttpClient, type CreateHttpClientParams } from './http-client';
import { useEffect, useRef } from 'react';

/**
 * This hook sets up an HTTP client that automatically attaches an access token
 * to requests.
 *
 * **Important:** This hook **must** be called **before** the HTTP client is used.
 * It should be initialized before mounting the `<RouterProvider>` to ensure
 * authenticated API calls function correctly.
 *
 * @param {string} params.baseURL - The base URL for API requests.
 *
 * @example
 * ```tsx
 * function InnerApp() {
 *   useInitHttpClient({
 *     baseURL: import.meta.env.VITE_WORKPLACE_API_URL,
 *   });
 *
 *   return (
 *     <BackgroundLayout>
 *       <RouterProvider router={router} context={{ auth }} />
 *     </BackgroundLayout>
 *   );
 * }
 * ```
 */
export const useInitHttpClient = ({
  baseURL,
}: Pick<CreateHttpClientParams, 'baseURL'>) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const isInitialized = useRef(false);

  useEffect(() => {
    if (isInitialized.current) return;
    createHttpClient({
      loginWithRedirect,
      getAccessTokenSilently,
      baseURL,
    });
    isInitialized.current = true;
  }, [loginWithRedirect, getAccessTokenSilently, baseURL]);
};
