import { z } from 'zod';

export const UploadRandomizationListFileTokenSchema = z.object({
  sessionId: z.string().uuid(),
  authorizedByUserId: z.string(),
});

export type UploadRandomizationListFileTokenDto = z.infer<
  typeof UploadRandomizationListFileTokenSchema
>;
