import { z } from 'zod';
import { ReasonsForTestingEnum } from '@abc-labs-ab/ts-events';

export const SamplingSessionReason = ReasonsForTestingEnum.extract([
  'random_selection',
  'new_employee',
  'incident_accident',
]);

export type SamplingSessionReason = z.infer<typeof SamplingSessionReason>;
