import { nonEmptyString } from '@careos/types';
import { z } from 'zod';

export const UnauthenticatedLinkAccountsRequestDtoSchema = z.object({
  encryptedPrimaryAccountToken: nonEmptyString,
  secondaryAccountId: nonEmptyString,
});

export type UnauthenticatedLinkAccountsRequestDto = z.infer<
  typeof UnauthenticatedLinkAccountsRequestDtoSchema
>;
