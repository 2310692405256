export enum OrganizationPolicyIdentifier {
  /**
   * Access policy for requisitions.
   */
  AccessRequisition = 'uri:careos.io/policy::organization/access/requisition',
  /**
   * Access policy for negative results.
   */
  AccessResultNeg = 'uri:careos.io/policy::organization/access/result/negative',
  /**
   * Access policy for negative results.
   */
  AccessResultPos = 'uri:careos.io/policy::organization/access/result/positive',
}
