import React from 'react';
import { Input } from '#components/Input/Input';
import { PersonalIdentityNumberSchema } from '@careos/toxicology-types';
import Personnummer from 'personnummer';
import { useTranslation } from 'react-i18next';
import type { ControllerRenderProps, FieldValues, Path } from 'react-hook-form';

type SwedishPersonalNumberInputProps<T extends FieldValues = FieldValues> =
  Omit<ControllerRenderProps<T, Path<T>>, 'onBlur'>;

export const SwedishPersonalNumberInput = React.forwardRef<
  HTMLInputElement,
  SwedishPersonalNumberInputProps
>(({ ...field }, ref) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'generic.form.swedishPersonalNumber',
  });

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const formattedValue = formatPersonalNumberIfValid(event.target.value);
    field.onChange(formattedValue);
  };

  return (
    <Input
      {...field}
      ref={ref}
      placeholder={t('placeholder')}
      onBlur={handleBlur}
    />
  );
});

SwedishPersonalNumberInput.displayName = 'SwedishPersonalNumberInput';

const formatPersonalNumberIfValid = (value: string) => {
  const parsedResult = PersonalIdentityNumberSchema.safeParse(value);
  if (!parsedResult.success) return value;

  const parsedPersonalNumber = Personnummer.parse(value);
  const longFormat = parsedPersonalNumber.format(true);

  const firstEight = longFormat.slice(0, 8);
  const lastFour = longFormat.slice(8);

  return `${firstEight}-${lastFour}`;
};
