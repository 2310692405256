import { genericEn } from './en-US';

export const genericSv: typeof genericEn = {
  retry: 'Försök igen',

  language: {
    language: 'Språk',
    english: 'Engelska',
    swedish: 'Svenska',
  },

  table: {
    no_results: 'Inga resultat.',
    pagination: {
      rows_per_page: 'Rader per sida:',
      item_count: '{{startRow}}-{{endRow}} av {{totalRows}}',
    },
  },

  navigation: {
    goToStartPage: 'Gå till startsidan',
    leave_warning:
      'Är du säker på att du vill lämna? Alla ändringar går förlorade.',
  },

  user_menu: {
    logout: 'Logga ut',
  },

  error: {
    title: 'Felmeddelande:',
    message: 'Ett fel inträffade',
    action:
      'Vänligen försök igen eller kontakta support om problemet kvarstår.',
  },

  form: {
    firstName: {
      label: 'Förnamn',
    },
    lastName: {
      label: 'Efternamn',
    },
    email: {
      label: 'E-postadress',
    },
    identificationSwitch: {
      description: 'Personen har ett svenskt personnummer',
    },
    swedishPersonalNumber: {
      label: 'Svenskt personnummer',
      placeholder: 'ÅÅÅÅMMDD-XXXX',
    },
    personalID: {
      label: 'Personligt ID',
    },
    employer: {
      label: 'Arbetsgivare',
      placeholder: 'Välj arbetsgivare',
    },

    optional: 'valfritt',
    back: 'Tillbaka',
    next: 'Nästa',
  },
  donorInteractionType: {
    label: 'Provtagningsform',
    placeholder: 'Välj provtagningsform',
    digital: 'Digitalt',
    options: {
      at_workplace: 'ABC på plats på din arbetsplats',
      at_abc_labs: 'Hos ABC i Stockholm',
      video_at_workplace: 'Digitalt av ABC på din arbetsplats',
    },
  },
};
