import { z } from 'zod';
import {
  InternalLocationSchema,
  IsoDateTimeSchema,
  ReferenceSchema,
} from '../fhir-entities';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

export const RackMovedInLabEventSchema = BaseEventSchema.extend({
  eventType: z.literal('RackMovedInLabEvent'),
  eventData: z.object({
    /**
     * Unique identifier of the rack.
     */
    rackId: z.string().uuid(),
    /**
     * When the rack was moved.
     */
    movedAt: IsoDateTimeSchema,
    /**
     * {@link Practitioner} who moved the rack.
     */
    practitioner: ReferenceSchema(z.literal('Practitioner')),
    /**
     * Reason for this move event.
     */
    moveReason: z.string(),
    /**
     * Location of this rack prior to this event.
     */
    fromLocation: InternalLocationSchema,
    /**
     * Destination at the end of this move event.
     */
    toLocation: InternalLocationSchema,
  }),
});

/**
 * A rack with specimens was moved in the lab.
 */
export class RackMovedInLabEvent extends createSchemaClass(
  RackMovedInLabEventSchema,
) {}
