import './polyfill.ts';
import { useCallback, useState } from 'react';
import { Document, Page, pdfjs, type DocumentProps } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { useEffect } from 'react';
import { cn } from '#utils';
import type { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/legacy/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

type PDFFile = string | File;
type Props = {
  pdfSource: PDFFile;
  className?: string;
  maxWidth?: number;
} & Omit<DocumentProps, 'file' | 'className'>;

export function PdfRender({
  pdfSource,
  maxWidth = 800,
  className,
  onLoadSuccess,
  ...rest
}: Props) {
  const [numPages, setNumPages] = useState(0);
  const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);
  const [containerWidth, setContainerWidth] = useState<number>();

  function onDocumentLoadSuccess(doc: PDFDocumentProxy) {
    setNumPages(doc.numPages);
    onLoadSuccess?.(doc);
  }
  const onResize = useCallback<ResizeObserverCallback>((entries) => {
    const [entry] = entries;

    if (entry) {
      setContainerWidth(entry.contentRect.width);
    }
  }, []);

  useResizeObserver({
    element: containerRef,
    observerCallback: onResize,
  });

  return (
    <div ref={setContainerRef}>
      <Document
        file={pdfSource}
        onLoadSuccess={onDocumentLoadSuccess}
        className={cn('w-fit space-y', className)}
        {...rest}
      >
        {numPages > 0 &&
          Array.from({ length: numPages }, (_, index) => index + 1).map(
            (pageNumber) => (
              <Page
                key={pageNumber}
                pageNumber={pageNumber}
                width={Math.min(containerWidth ?? maxWidth, maxWidth)}
                className="[box-shadow:0_2px_8px_4px_rgba(0,0,0,0.1)]"
              />
            ),
          )}
      </Document>
    </div>
  );
}

/**
 * Observes a given element using ResizeObserver - https://github.com/wojtekmaj/react-hooks/blob/main/src/useResizeObserver.ts
 *
 * @param {Element} [element] Element to attach ResizeObserver to
 * @param {ResizeObserverOptions} [options] ResizeObserver options. WARNING! If you define the
 *   object in component body, make sure to memoize it.
 * @param {ResizeObserverCallback} observerCallback ResizeObserver callback. WARNING! If you define
 *   the function in component body, make sure to memoize it.
 * @returns {void}
 */

type ResizeObserverArgs = {
  element: Element | null;
  observerCallback: ResizeObserverCallback;
  options?: ResizeObserverOptions;
};
function useResizeObserver({
  element,
  observerCallback,
  options,
}: ResizeObserverArgs): void {
  useEffect(() => {
    if (!element || !('ResizeObserver' in window)) {
      return;
    }

    const observer = new ResizeObserver(observerCallback);

    observer.observe(element, options);

    return () => {
      observer.disconnect();
    };
  }, [element, options, observerCallback]);
}
