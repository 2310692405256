import { z } from 'zod';
import { IsoDateSchema, ReferenceSchema } from '../fhir-entities';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

export const RandomNumberSeriesGeneratedEventSchema = BaseEventSchema.extend({
  eventType: z.literal('RandomNumberSeriesGeneratedEvent'),
  eventData: z.object({
    /**
     * Reference to the session
     */
    sessionId: z.string().optional(),

    /**
     * Time when the series was generated.
     */
    generatedAt: IsoDateSchema,

    /**
     * The random series, like [1, 4, 74, 23].
     */
    numberSeries: z.array(z.number().int().positive()),

    /**
     * The biggest number in the generation, the lowest is always 1.
     */
    maxNumber: z.number().int().positive(),

    /**
     * The amount of random numbers to generate.
     */
    amountToGenerate: z.number().int().positive(),

    /**
     * {@link Practitioner} who requested the randomization.
     */
    practitioner: ReferenceSchema(z.literal('Practitioner')),
  }),
});
/**
 *  Represents when a user generates a random number series.
 */
export class RandomNumberSeriesGeneratedEvent extends createSchemaClass(
  RandomNumberSeriesGeneratedEventSchema,
) {}
