import { Button } from '@careos/react-ui/Button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@careos/react-ui/DropdownMenu';
import type { OrderReason, SamplingSessionStatus } from '@careos/toxicology-types';
import { Link } from '@tanstack/react-router';
import { t } from 'i18next';
import { MoreHorizontal } from 'lucide-react';

import { useRandomizationList } from '../hooks/randomization-list';

export type ActionMenuCallbackProps = {
  onStartSession: (sessionId: string) => void;
  onFinishSession: (sessionId: string) => void;
  onOpenNumberGenerator: (sessionId: string) => void;
};

type ActionMenuProps = {
  sessionId: string;
  reasonForTesting: OrderReason;
  status: SamplingSessionStatus;
  hasGeneratedNumberSeries: boolean;
  randomizationListExists: boolean;
} & ActionMenuCallbackProps;

export const ActionMenu = ({
  sessionId,
  reasonForTesting,
  status,
  hasGeneratedNumberSeries,
  randomizationListExists,
  onStartSession,
  onFinishSession,
  onOpenNumberGenerator,
}: ActionMenuProps) => {
  const { downloadList, generateAndCopyLink } = useRandomizationList(sessionId);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="relative size-10 p-0">
          <span className="sr-only">{t('table.actions.open_menu_sr_only')}</span>

          <MoreHorizontal className="size-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>{t('table.actions.menu_label')}</DropdownMenuLabel>

        <DropdownMenuSeparator />
        {reasonForTesting === 'random_selection' && (
          <>
            <DropdownMenuItem onClick={() => onOpenNumberGenerator(sessionId)}>
              {hasGeneratedNumberSeries
                ? t('table.actions.show_numbers')
                : t('table.actions.generate_numbers')}
            </DropdownMenuItem>

            <DropdownMenuItem disabled={!randomizationListExists} onClick={() => downloadList()}>
              {t('table.actions.download_randomization_list')}
            </DropdownMenuItem>

            {!randomizationListExists && (
              <DropdownMenuItem onClick={() => generateAndCopyLink()}>
                {t('translation:table.actions.copy_randomization_list_url')}
              </DropdownMenuItem>
            )}
            <DropdownMenuSeparator />
          </>
        )}
        {status === 'requested' && (
          <DropdownMenuItem onClick={() => onStartSession(sessionId)}>
            {t('table.actions.start_sampling')}
          </DropdownMenuItem>
        )}
        {status === 'started' && (
          <>
            <DropdownMenuItem asChild>
              <Link from="/sessions" to="/sessions/$sessionId/test-info" params={{ sessionId }}>
                {t('table.actions.resume_sampling')}
              </Link>
            </DropdownMenuItem>

            <DropdownMenuItem onClick={() => onFinishSession(sessionId)}>
              {t('table.actions.end_sampling')}
            </DropdownMenuItem>
          </>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
