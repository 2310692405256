import { bankidMessagesEn } from './en-US';

export const bankidMessagesSv: typeof bankidMessagesEn = {
  'sign-with-bankid': 'Skriv under med BankID',
  'try-again': 'Försök igen',
  cancel: 'Avbryt',

  'sign-success': 'Underskriften lyckades!',

  // Pending
  'hintcode-qr-outstandingTransaction':
    'Starta BankID-appen och tryck på QR-ikonen. Skanna sedan QR-koden nedan.', // RFA1
  'hintcode-mobile-outstandingTransaction':
    'Klicka på knappen nedan för att starta BankID-appen.',

  'hintcode-noClient':
    'Starta BankID-appen och tryck på QR-ikonen. Skanna sedan QR-koden nedan.', // RFA1

  'hintcode-qr-started':
    'Starta BankID-appen och tryck på QR-ikonen. Skanna sedan QR-koden nedan.', // RFA15 (tailored for qr)
  'hintcode-mobile-started':
    'Klicka på knappen nedan för att starta BankID-appen.',

  'hintcode-userSign':
    'Skriv in din säkerhetskod i BankID-appen och välj Identifiera eller Skriv under.', // RFA9

  'hintcode-unknown': 'Identifiering eller underskrift pågår.', // RFA21

  'open-bankid-app': 'Öppna BankID',
  // Failed

  'hintcode-expiredTransaction':
    'BankID-appen svarar inte. Kontrollera att den är startad och att du har internetanslutning. Om du inte har något giltigt BankID kan du skaffa ett hos din bank. Försök sedan igen.', //RFA8

  'hintcode-certificateErr':
    'Det BankID du försöker använda är för gammalt eller spärrat. Använd ett annat BankID eller skaffa ett nytt hos din internetbank.',

  'hintcode-userCancel': 'Åtgärden avbröts.', //RFA6

  'hintcode-cancelled': 'Åtgärden avbruten. Försök igen', // RFA3

  'hintcode-qr-startFailed':
    'Skanningen misslyckades. Starta BankID-appen och skanna QR koden. Kontrollera att appen är uppdaterad. Om du inte har appen måste du installera den och skaffa ett BankID hos din internetbank. Installera appen från din appbutik eller https://install.bankid.com.', // RFA17
  'hintcode-mobile-startFailed':
    'BankID-appen verkar inte finnas i din dator eller telefon. Installera den och skaffa ett BankID hos din internetbank. Installera appen från din appbutik eller https://install.bankid.com.',

  'hintcode-failed-unknown': 'Något gick fel. Försök igen.', // RFA21

  // Error

  'errorcode-alreadyInProgress':
    'En identifiering eller underskrift för det här personnumret är redan påbörjad. Försök igen.', // RFA4

  'errorcode-invalidParameters': 'Något gick fel.',

  'errorcode-unknown': 'Okänt fel. Försök igen.', // RFA22

  'errorcode-unauthorized': 'Något gick fel.',

  'errorcode-notFound': 'Något gick fel.',

  'errorcode-methodNotAllowed': 'Något gick fel.',

  'errorcode-requestTimeout': 'Internt tekniskt fel. Försök igen.', // RFA5

  'errorcode-unsupportedMediaType': 'Något gick fel.',

  'errorcode-internalError': 'Internt tekniskt fel. Försök igen.', // RFA5

  'errorcode-maintenance': 'Internt tekniskt fel. Försök igen.', // RFA5

  'errorcode-careosUnknown': 'Något gick fel.',

  // Common
  'hintcode-qr-unknown': '',
  'hintcode-qr-userMrtd': '',
  'hintcode-qr-userCallConfirm': '',
  'hintcode-qr-userDeclinedCall': '',
  'hintcode-qr-notSupportedByUserApp': '',
};
