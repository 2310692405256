import {
  DropdownMenuSub,
  DropdownMenuSubTrigger,
  DropdownMenuPortal,
  DropdownMenuSubContent,
  DropdownMenuCheckboxItem,
} from '#components/DropdownMenu/DropdownMenu';
import { Languages } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { type SupportedLanguage, languageMapping } from './Header.constants';

export type LanguageSelectorProps = {
  onLanguageChange?: (language: SupportedLanguage) => void;
  supportedLanguages: SupportedLanguage[];
};

export const LanguageSelector = ({
  onLanguageChange,
  supportedLanguages,
}: LanguageSelectorProps) => {
  const { t, i18n } = useTranslation('translation', {
    keyPrefix: 'generic.language',
  });

  const handleLanguageChange = (language: SupportedLanguage) => {
    i18n.changeLanguage(language);
    if (onLanguageChange) {
      onLanguageChange(language);
    }
  };

  return (
    <DropdownMenuSub>
      <DropdownMenuSubTrigger>
        <Languages className="mr-2 size-4" />
        {t('language')}
      </DropdownMenuSubTrigger>
      <DropdownMenuPortal>
        <DropdownMenuSubContent>
          {supportedLanguages.map((lang) => (
            <DropdownMenuCheckboxItem
              key={lang}
              checked={i18n.language === lang}
              onCheckedChange={() => handleLanguageChange(lang)}
            >
              {t(languageMapping[lang])}
            </DropdownMenuCheckboxItem>
          ))}
        </DropdownMenuSubContent>
      </DropdownMenuPortal>
    </DropdownMenuSub>
  );
};
