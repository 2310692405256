import { nonEmptyString } from '@careos/types';
import { z } from 'zod';

export const LinkAccountsRequestDtoSchema = z.object({
  primaryAccountId: nonEmptyString,
  secondaryAccountId: nonEmptyString,
});

export type LinkAccountsRequestDto = z.infer<
  typeof LinkAccountsRequestDtoSchema
>;
