import { nonEmptyString } from '@careos/types';

import { z } from 'zod';

const BaseAuth0UserSchema = z.object({
  name: nonEmptyString,
  given_name: z.string().optional(),
  family_name: z.string().optional(),
  middle_name: z.string().optional(),
  nickname: z.string().optional(),
  preferred_username: z.string().optional(),
  profile: z.string().optional(),
  picture: z.string().optional(),
  website: z.string().optional(),
  email: z.string().email(),
  email_verified: z.boolean().optional(),
  gender: z.string().optional(),
  birthdate: z.string().optional(),
  locale: z.string().optional(),
  phone_number: z.string().optional(),
  phone_number_verified: z.boolean().optional(),
  address: z.string().optional(),
  updated_at: z.string().optional(),
  sub: z.string().optional(),
});

const Auth0OrganizationSchema = z.object({
  display_name: nonEmptyString,
  id: z.string().optional(),
  metadata: z.object({
    careos_org_id: z.string().optional(),
    careos_org_types: z.string().optional(),
    org_prefix: z.string().optional(),
  }),
  name: z.string().optional(),
});
export type Auth0Organization = z.infer<typeof Auth0OrganizationSchema>;

export const Auth0CareosUserSchema = BaseAuth0UserSchema.extend({
  connections: z.array(z.string()).optional(),
  org_id: z.string().optional(),
  org_name: z.string().optional(),
  organization: Auth0OrganizationSchema,
});

export type Auth0CareosUser = z.infer<typeof Auth0CareosUserSchema>;
