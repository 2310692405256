import { z } from 'zod';
import {
  CodeableReferenceSchema,
  IdentifierSchema,
  IsoDateTimeSchema,
  OrganizationSchema,
  ReferenceTypeSchema,
} from '../fhir-entities';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

export const BasePolicySchema = z.object({
  /**
   * Identifier of the policy.
   *
   * @example The identifier for requisition access policy:
   * identifier: {
   *   system: 'uri:careos.io/policy',
   *   value: 'uri:careos.io/policy::organization/access/requisition',
   * }
   */
  identifier: IdentifierSchema,
});

export const MAX_TTL_SEC = 2147483647; // Max value for a 32-bit signed integer
export const TTLSecSchema = z.number().positive().max(MAX_TTL_SEC);

const OrganizationAccessPolicySchema = BasePolicySchema.extend({
  /**
   * Organization access policies are applied to either requisitions or results.
   * They are specific to a reason-for-testing. Because, this enables for
   * example results for random-sampling and new-employment to have distinct TTLs.
   */
  reasonForTesting: CodeableReferenceSchema(ReferenceTypeSchema),
  /**
   * Time-to-live in seconds for this particular access policy.
   */
  value: TTLSecSchema,
});

export const OrganizationPolicyUpdatedEventSchema = BaseEventSchema.extend({
  eventType: z.literal('OrganizationPolicyUpdatedEvent'),
  eventData: z.object({
    /**
     * The new access policy to set in organization.
     */
    policyUpdate: OrganizationAccessPolicySchema,
    /**
     * The organization the policy applies to.
     */
    organization: OrganizationSchema,
    /**
     * Reference to the user who took the action.
     */
    user: IdentifierSchema,
    /**
     * Timestamp of the action.
     */
    registeredAt: IsoDateTimeSchema,
  }),
});

/**
 * This event is triggered when the access policy configurations for an organization is updated.
 */
export class OrganizationPolicyUpdatedEvent extends createSchemaClass(
  OrganizationPolicyUpdatedEventSchema,
) {}
