import { cn } from '#utils';
import type { BankIdResponse } from './BankId';

type BankIdLayoutProps = Pick<BankIdResponse, 'status'> & {
  children: React.ReactNode;
  className?: string;
};

export const BankIdLayout = ({
  status,
  children,
  className,
}: BankIdLayoutProps) => {
  const errorClasses = 'bg-red-100 text-red-950';
  const statusColor = {
    pending: 'bg-white text-black',
    complete: 'bg-green-100 text-green-900',
    failed: errorClasses,
    error: errorClasses,
  }[status];

  return (
    <div
      className={cn(
        'text-center flex flex-col items-center p-8 md:p-16 min-h-[500px] relative w-full',
        statusColor,
        className,
      )}
    >
      {children}
    </div>
  );
};
