import { queryOptions, useQuery } from '@tanstack/react-query';

import { api } from '@/lib/api';
import type { QueryConfig } from '@/lib/react-query';

export const getRandomizationListFileQueryOptions = (sessionId: string) => {
  return queryOptions({
    queryKey: ['randomization-list-file', sessionId],
    queryFn: () => api.samplingSession.getRandomizationListFile(sessionId),
  });
};

export const useRandomizationListFile = ({
  sessionId,
  queryConfig,
}: {
  sessionId: string;
  queryConfig?: QueryConfig<typeof getRandomizationListFileQueryOptions>;
}) => {
  return useQuery({
    ...getRandomizationListFileQueryOptions(sessionId),
    ...queryConfig,
  });
};
