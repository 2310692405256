import { Button } from '@careos/react-ui/Button';
import { toast } from '@careos/react-ui/Toast';
import { t } from 'i18next';
import { CopyIcon } from 'lucide-react';

type RequisitionFormSignRemoteCopyLinkButtonProps = {
  signingLink: string;
};

export async function handleCopyLink(signingLink: string) {
  await navigator.clipboard.writeText(signingLink);
  toast({
    title: t('requisition_form.signing.link_copied'),
  });
}

export const RequisitionFormSignRemoteCopyLinkButton = ({
  signingLink,
}: RequisitionFormSignRemoteCopyLinkButtonProps) => {
  return (
    <Button className="ml-2 px-5 py-7" onClick={() => handleCopyLink(signingLink)}>
      <CopyIcon />
    </Button>
  );
};
