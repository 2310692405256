import type { SamplingKitIdentifier, ToxicologyActivityIdentifier } from '@careos/identifiers';
import { PanelSize, type SamplingSessionRequisition } from '@careos/toxicology-types';
import { createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const columnHelper = createColumnHelper<SamplingSessionRequisition>();
const NO_DATA = '-';

export const useRequisitionColumns = () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      columnHelper.accessor('receivedAt', {
        header: t('created_requisition_details.created'),
        cell: ({ getValue }) => {
          const value = getValue();
          const date = new Date(value);
          const formattedDate = format(date, 'yyyy-MM-dd HH:mm');
          return formattedDate;
        },
      }),
      columnHelper.accessor('barcode', {
        header: t('created_requisition_details.barcode'),
      }),
      columnHelper.accessor('testType', {
        header: t('created_requisition_details.test_type'),
      }),
      columnHelper.accessor('sampleType', {
        header: t('created_requisition_details.sample_type'),
        cell: ({ getValue }) => {
          return t(`sample_type.${getValue<ToxicologyActivityIdentifier>()}`);
        },
      }),
      columnHelper.accessor('samplingKit', {
        header: t('created_requisition_details.sampling_kit'),
        cell: ({ getValue }) => {
          return getValue() ? t(`sampling_kit.${getValue<SamplingKitIdentifier>()}`) : NO_DATA;
        },
      }),
      columnHelper.accessor('panelSize', {
        header: t('created_requisition_details.panel_size'),
        cell: ({ getValue }) => {
          return getValue() ? t(`panel.${getValue<PanelSize>()}` as any) : NO_DATA;
        },
      }),
      columnHelper.accessor('additionalSubstances', {
        header: t('created_requisition_details.additional_substances'),
        cell: ({ getValue }) => {
          const substances = getValue();
          if (!substances?.length) return NO_DATA;
          return substances
            ?.map((substance: string) => t(`substances.${substance}` as any))
            .join(', ');
        },
      }),
    ],
    [t],
  );
};
