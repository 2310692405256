import { z } from 'zod';

const NonEmptyArrayStringSchema = z.array(z.string()).min(1);

export const GetUserEmailsByIdsAndOrganizationsRequestDtoSchema = z.object({
  userIds: NonEmptyArrayStringSchema,
  organizationKeys: NonEmptyArrayStringSchema,
});
export type GetUserEmailsByIdsAndOrganizationsRequestDto = z.infer<
  typeof GetUserEmailsByIdsAndOrganizationsRequestDtoSchema
>;
