import {
  TestType,
  type GetEmployersAndEnabledTestInfoResponseDto,
} from '@careos/organization-api-types';
import { queryOptions, useQuery } from '@tanstack/react-query';

import { api } from '@/lib/api';
import { type QueryConfig } from '@/lib/react-query';

export type CollectionOrderOptions = NonNullable<ReturnType<typeof useOrderOptions>['data']>;

const selectOrderOptions = (data: GetEmployersAndEnabledTestInfoResponseDto) => {
  const enabledTestTypes = data.testInfo.map((it) => it.testType);
  const doaTestInfo = data.testInfo.find((it) => it.testType === TestType.DoA);

  return {
    employers: data.employers,
    enabledTestTypes,
    enabledSampleTypeAndKits: doaTestInfo?.sampleTypeAndEnabledKits || [],
  };
};

export const getSessionOrderOptionsQueryOptions = () => {
  return queryOptions({
    queryKey: ['order-options'],
    queryFn: () => api.config.getOrderOptions(),
    select: (data) => selectOrderOptions(data),
  });
};

export const useOrderOptions = ({
  queryConfig,
}: {
  queryConfig?: QueryConfig<typeof getSessionOrderOptionsQueryOptions>;
} = {}) => {
  return useQuery({
    ...getSessionOrderOptionsQueryOptions(),
    ...queryConfig,
  });
};
